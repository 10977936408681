import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CoverSEO from "./shared/CoverSEO";
import ReviewSlider from "./shared/ReviewSlider";
import FAQ from "./shared/FAQ";

const questions = [
  "¿Qué son los préstamos inmediatos en línea?",
  "¿Cómo puedo solicitar un préstamo inmediato en Vivus?",
  "¿Puedo obtener un préstamo inmediato si estoy en buró de crédito?",
  "¿Qué monto puedo solicitar en un préstamo inmediato?",
  "¿Qué tan seguros son los préstamos en línea de Vivus?",
  "¿Cuánto tiempo tarda en aprobarse mi solicitud?",
  "¿Qué puedo hacer si necesito más tiempo para pagar mi préstamo?",
  "¿Cuál es la diferencia entre un préstamo urgente y un préstamo inmediato?",
];

const answers = [
  "Son préstamos que se procesan y desembolsan rápidamente a través de plataformas en línea, permitiendo obtener el dinero necesario en cuestión de minutos.",
  "Debes registrarte en nuestro sitio web, completar el formulario de solicitud con tus datos personales y financieros, seleccionar el monto y plazo de pago, y esperar la aprobación.",
  "Sí, en Vivus ofrecemos préstamos en línea sin buró de crédito, evaluando tu capacidad actual de pago.",
  "Puedes solicitar desde pequeños montos hasta préstamos de 10 mil pesos, dependiendo de tus necesidades y capacidad de pago.",
  "Nuestros préstamos en línea son seguros y confiables, utilizando tecnología avanzada de encriptación para proteger tu información.",
  "Las solicitudes de préstamos inmediatos se aprueban en pocos minutos, permitiéndote acceder al dinero rápidamente.",
  "Puedes solicitar una extensión del plazo de pago a través de nuestra plataforma en línea o contactando a nuestro equipo de atención al cliente.",
  "Un préstamo urgente es una necesidad de financiamiento que requiere resolución rápida, mientras que un préstamo inmediato se refiere a la capacidad de obtener el préstamo en minutos, generalmente a través de plataformas en línea como Vivus.",
];

const SolicitudCredito = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Solicitud de Crédito Instantánea: ¡Vivus te Resuelve! | Vivus Mx</title>
        <meta name="title" content="Solicitud de Crédito Instantánea: ¡Vivus te Resuelve! | Vivus Mx" />
        <meta name="description" content="Solicitud de crédito rápido con Vivus te ofrece préstamos en línea rápidos y fáciles. Solicita en minutos tu crédito." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="relative w-full flex justify-center flex-1 lg:pb-12 flex-col items-center text-[#65696E]">
        <CoverSEO title="¿Necesitas dinero? Inicia tu solicitud de crédito" description="Solicitud de crédito rápido con Vivus te ofrece préstamos en línea rápidos y fáciles. Solicita en minutos tu crédito." />
        <div className="container flex flex-col gap-4 p-4">
          {/* <img src={`/img/seo/seo_prestamos_inmediatos.jpg`} alt="préstamos inmediatos" className="w-full object-cover" /> */}
          <p>
            En Vivus, entendemos que la vida está llena de imprevistos y que a veces necesitas una solución financiera rápida. Por eso, te ofrecemos{" "}
            <a href="https://www.vivus.com.mx" className="underline text-green-dark">
              préstamos en línea
            </a>{" "}
            que puedes solicitar en minutos y recibir el dinero directo a tu cuenta
          </p>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href={"https://www.vivus.com.mx/registration"}>
            ¡Inicia tu solicitud de crédito ahora!
          </a>
          <p>Nuestro proceso es sencillo y transparente, sin papeleos ni trámites engorrosos. ¡Obtén el dinero que necesitas para cubrir cualquier imprevisto de forma rápida y segura!</p>

          <h2 className="text-xl font-bold">¿Cómo funciona la solicitud de crédito? ¡Más fácil imposible!</h2>
          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Solicitud</b>: Crea tu cuenta en{" "}
              <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
                VIVUS
              </a>
              .
            </li>
            <li>
              <b className="font-bold">Registro</b>: Ingresa tus datos personales.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Tu solicitud será evaluada rápidamente, y en cuestión de minutos, recibirás una respuesta.
            </li>
            <li>
              <b className="font-bold">Depósito del dinero</b>: Si tu solicitud es aprobada, el dinero se deposita directamente en tu cuenta bancaria, listo para ser usado según tus necesidades.
            </li>
            <li>
              <b className="font-bold">*¡Dinero en tu cuenta!</b>: Si todo está en orden, tendrás el dinero en tu cuenta.
            </li>
          </ol>
          <p>*Sujeto a verificación de datos y aprobación de crédito.</p>

          <h2 className="text-xl font-bold">¿Por qué elegir VIVUS?</h2>

          <ul className="list-disc pl-5">
            <li>Olvídate de trámites engorrosos, todo es online.</li>
            <li>Sin letra pequeña ni sorpresas.</li>
            <li>Protegemos tus datos personales.</li>
            <li>Tú eliges cuánto y cuándo pagas.</li>
          </ul>
          <h2 className="text-xl font-bold">¿Qué puedes hacer con un préstamo Vivus?</h2>
          <p>¡Lo qué deseas! Desde pagar una factura inesperada hasta darte un gusto, tú decides. Nuestros préstamos personales se adaptan a tus necesidades, ya sea que necesites cubrir un imprevisto, consolidar deudas o financiar un proyecto personal.</p>
          <p className="font-bold">¡Y eso no es todo! Planifica tus pagos con nuestra calculadora de préstamos</p>
          <p className="font-bold">
            Además de la rapidez y facilidad, en Vivus queremos que tengas el control total de tus finanzas. Por eso, ponemos a tu disposición nuestra calculadora de préstamos. Con ella, podrás simular diferentes escenarios y conocer el costo total de tu préstamo antes de solicitarlo. Así, podrás
            tomar la mejor decisión para tu bolsillo.
          </p>
          <p className="font-bold">¡No esperes más!</p>
          <p className="font-bold">¡Vivus, tu aliado financiero!</p>
          <h2 className="text-xl font-bold">¿Qué necesitas para la solicitud de crédito?</h2>
          <ul className="font-bold list-disc pl-5">
            <li>
              Para obtener el préstamo debes:
              <ol className="list-decimal pl-5">
                <li>Ser ciudadano mexicano(a). </li>
                <li>Ser mayor de 18 años.</li>
                <li>Ser empleado, profesionista independiente o contar con ingreso regular.</li>
                <li>Tener un teléfono celular y un correo electrónico. </li>
                <li>Completar toda la información requerida en la solicitud de préstamo.</li>
                <li>Enviar la documentación solicitada.</li>
              </ol>
            </li>
          </ul>
          <h2 className="text-xl font-bold">¡Solicita tu préstamo Vivus hoy mismo!</h2>
          <p>No esperes más para obtener el dinero que necesitas. Visita nuestro sitio web y descubre cómo Vivus puede ayudarte.</p>
        </div>
      </div>
    </Suspense>
  );
};

SolicitudCredito.propTypes = {};
SolicitudCredito.defaultValues = {};

export default SolicitudCredito;
