import React from "react";
import TagManager from "react-gtm-module";
// import ReactPixel from 'react-facebook-pixel'
import Pixel from "./pixel";
import { Helmet } from "react-helmet";

export const LoanReview = () => {
  //TAG MANAGER
  const tagManagerArgs = {
    dataLayer: {
      event: "pageChange",
      page: {
        url: "/loan-review",
        referrer: sessionStorage.getItem("utm") || "/",
      },
    },
    dataLayerName: "dataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);

  return (
    <>
      <Helmet>
        <title>Estamos trabajando en tu solicitud | Vivus </title>
        <meta name="title" content="Estamos trabajando en tu solicitud | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-8 px-4 py-12 self-center">
          <div className="w-full flex flex-col gap-8 text-center items-center">
            <h1 className="text-4xl lg:text-6xl font-bold text-green-dark"> Estamos trabajando en tu solicitud</h1>
            <p className="text-2xl">Nos pondremos en contacto contigo una vez que completemos la revisión de tu información.</p>
            <img src="/img/review.png" width={120} />

            <Pixel name="PIXEL 1" />
          </div>
        </div>
      </div>
    </>
  );
};
