import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import TagManager from "react-gtm-module";
// import ReactPixel from 'react-facebook-pixel'
import Pixel from "./pixel";
import { Helmet } from "react-helmet";

export const IdentityValidated = () => {
  const [email] = useState(sessionStorage.getItem("user-email") || "");

  //TAG MANAGER
  const tagManagerArgs = {
    dataLayer: {
      event: "pageChange",
      page: {
        url: "/identity-validated",
        referrer: sessionStorage.getItem("utm") || "/",
      },
    },
    dataLayerName: "dataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);
  //TAG MANAGER
  // let options = {autoConfig: true, debug: true}
  // ReactPixel.init('285010758598337', options)
  // ReactPixel.pageView()
  return (
    <>
      <Helmet>
        <title>Préstamo aprobado | Vivus </title>
        <meta name="title" content="Préstamo aprobado | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-8 px-4 py-12 self-center">
          <div className="w-full flex flex-col gap-8 text-center">
            <h1 className="text-4xl lg:text-6xl font-bold text-green-dark"> Tu préstamo ha sido aprobado.</h1>
            <p className="text-2xl">Estaremos contactandote para confirmarte cuándo recibirás el préstamo.</p>
            <Pixel name="PIXEL 1" />
            <input type="hidden" name="eMail" id="email" value={email} />
          </div>
        </div>
      </div>
    </>
  );
};
