import React, { useState, useEffect } from "react";
import { Obtain } from "./Obtain";
import { Restructure } from "./Restructure";
import { AdditionalAmount } from "./AdditionalAmount";
import { FAQ } from "./FAQ";
import "./works.scss";
import TagManager from "react-gtm-module";
import { HowToPay } from "./HowToPay";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/como-funciona",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};

const jsonLd = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  lastReviewed: "2023-12-28T00:00:00+00:00",
};

export const HowItWorks = () => {
  TagManager.dataLayer(tagManagerArgs);
  const [section, setSection] = useState(1);
  const location = useLocation();
  useEffect(() => {
    setSection(parseInt(new URLSearchParams(location.search).get("section") || 1));
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Cómo Funciona Vivus México: Guía de Préstamos en Línea</title>
        <meta name="title" content="Cómo Funciona Vivus México: Guía de Préstamos en Línea" />
        <meta name="description" content="Conoce cómo funcionan los préstamos personales en línea de Vivus México. Haz clic y comienza tu solicitud online. Obtén respuesta en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host} />
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <div className="flex flex-col items-center flex-1">
        <div className="flex justify-center w-full bg-green-light">
          <div className="flex container flex-col lg:flex-row gap-4 justify-between py-8 px-4 bg-green-light text-white font-bold">
            <div className={`w-92 py-2 px-4 rounded-lg hover:text-green-light hover:bg-white flex cursor-pointer items-center justify-center ${section === 1 ? "bg-white text-green-light" : null}`} onClick={() => setSection(1)}>
              ¿Cómo puedo obtener un préstamo?
            </div>
            <div className={`w-92 py-2 px-4 rounded-lg hover:text-green-light hover:bg-white flex cursor-pointer items-center justify-center ${section === 2 ? "bg-white text-green-light" : null}`} onClick={() => setSection(2)}>
              ¿Cómo reestructuro el plazo de mi préstamo?
            </div>
            <div className={`w-92 py-2 px-4 rounded-lg hover:text-green-light hover:bg-white flex cursor-pointer items-center justify-center ${section === 3 ? "bg-white text-green-light" : null}`} onClick={() => setSection(3)}>
              ¿Cómo pagar mi préstamo?
            </div>
            <div className={`w-92 py-2 px-4 rounded-lg hover:text-green-light hover:bg-white flex cursor-pointer items-center justify-center ${section === 4 ? "bg-white text-green-light" : null}`} onClick={() => setSection(4)}>
              Preguntas frecuentes
            </div>
          </div>
        </div>
        {section === 1 ? <Obtain /> : section === 2 ? <Restructure /> : section === 3 ? <HowToPay /> : section === 4 ? <FAQ /> : <Obtain />}
      </div>
    </>
  );
};
