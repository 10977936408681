import React, { useState, useEffect } from "react";
import "./newStyles.scss";
import { withRouter } from "react-router-dom";
import { getToken, getStatus, getAnalytics, setDirectDebitAuthorization, setIdValidation, getDocumentList } from "../../services/api";
import TagManager from "react-gtm-module";
import publicIp from "public-ip";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import DocumentItem from "../common/DocumentItem";
import MainButton from "../common/MainButton";
import { useAnalytics } from "../../hooks/use-analytics";
import { Helmet } from "react-helmet";

const idProduct = 1;

const Identificacion = (props) => {
  const [cargadoAnverso, setCargadoAnverso] = useState(false);
  const [cargadoReverso, setCargadoReverso] = useState(false);
  const [user, setUser] = useState(null);
  const [, setToken] = useState(null);
  const [, setMobile] = useState(false);
  // variables validación ID
  const [, setIdValid] = useState(false);
  const [idLoading, setIdLoading] = useState(false);
  const [idError, setIdError] = useState(false);
  const [opportunities, setOpportunities] = useState(0);
  const [continueDisabled, setContinueDisabled] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [ready, setReady] = useState(false);

  const { trackPageView } = useMatomo();
  const { dataLayerPushEvent } = useAnalytics();

  /**
   * Get token to request.
   * @returns
   */
  const askToken = async () => {
    let response = await getToken();
    if (!response) return;
    setToken(response.data.token);
  };

  /**
   * Pass a value to determine if the specified document has been loaded.
   * @param {number} idDocument
   */
  const validateDocument = (idDocument) => {
    setDocumentList(
      documentList.map((doc, key) => {
        if (doc.idDocument === idDocument) {
          doc.uploaded = true;
        }
        return doc;
      })
    );
  };

  /**
   * It is called once the form is completed.
   */
  const manageValidarId = async () => {
    const myIp = await publicIp.v4();
    let token = await getToken();
    token = token.data.token;
    let setIdData = {
      message: "",
      identityValidated: false,
      userAgent: navigator.userAgent,
      ip: myIp,
      idCustomer: user.customerId,
      idProduct: idProduct,
      token,
    };
    setIdLoading(false);
    setIdValid(true);
    setIdError(false);
    setIdData.message = "OK: ID y selfie válidos.";
    setIdData.identityValidated = true;

    setIdValidation(setIdData)
      .then(setDebitAuth)
      .then(() => {
        getStatus(idProduct, user.customerId, false, token).then(async (res) => {
          await window.juicyScoreApi?.restart();
          if (res.status && res.data.idStatus === 4) {
            trackPageView({
              documentTitle: "Form Rejected",
              href: "https://dfi.world",
            });
            localStorage.id_opportunities = 0;
            props.history.push("/rejected");
          } else {
            trackPageView({
              documentTitle: "Final Approval",
              href: "https://dfi.world",
            });

            trackPageView({
              documentTitle: "Successful loan signing",
              href: "https://dfi.world",
            });

            dataLayerPushEvent("Credit_approved", user.customerId);
            localStorage.id_opportunities = 0;
            props.history.push("/identity-validated");
          }
        });
      })
      .catch(async () => {
        await window.juicyScoreApi?.restart();
        trackPageView({
          documentTitle: "Form Rejected",
          href: "https://dfi.world",
        });
        props.history.push("/denied");
      });
  };

  /**
   * Handle button continue.
   */
  const handleContinueClick = async () => {
    setContinueDisabled(true);
    const myIp = await publicIp.v4();
    let token = await getToken();
    token = token.data.token;
    let setIdData = {
      message: "",
      identityValidated: false,
      userAgent: navigator.userAgent,
      ip: myIp,
      idCustomer: user.customerId,
      idProduct: idProduct,
      token: token,
    };

    await manageValidarId(setIdData);
  };

  useEffect(() => {
    const checkUser = async (user) => {
      let response = await getToken();
      if (!response) return;
      let validToken = response.data.token;
      getStatus(idProduct, user.customerId, false, validToken).then((res) => {
        if (res.status && res.data.idStatus === 1) {
          if (res.data.idSubStatus === 180) return props.history.push("/registration/personal-details");
          if (res.data.idSubStatus === 181) return props.history.push("/registration/employment-details");
          if (res.data.idSubStatus === 182) return props.history.push("/registration/nip-bureau");
          if (res.data.idSubStatus === 183) return props.history.push("/registration/nip-bureau");
          if (res.data.idSubStatus === 184) return props.history.push("/registration/nip-bureau");
          if (res.data.idSubStatus === 185) return props.history.push("/registration/nip-bureau");
          if (res.data.idSubStatus === 195) return props.history.push("/registration-complete");
          if (res.data.idSubStatus === 196) return props.history.push("/pre-approved");
          if (res.data.idSubStatus === 203) return props.history.push("/pre-approved");
          if (res.data.idSubStatus === 206) return props.history.push("/application-review");
          if (res.data.idSubStatus === 217) return props.history.push("/dashboard/id");
          if (res.data.idSubStatus === 254) return props.history.push("/denied");
          if (res.data.idSubStatus === 257) return props.history.push("/identity-validated");
          if (res.data.idSubStatus === 247) return props.history.push("/dashboard/id");

          if (res.data.idSubStatus === 270) return props.history.push("/loan-review");
          if (res.data.idSubStatus === 271) return props.history.push("/loan-review");
          if (res.data.idSubStatus === 273) return props.history.push("/loan-review");

          if (res.data.idSubStatus === 218) {
            setCargadoAnverso(true);
            return setCargadoReverso(true);
          }
          if (res.data.idSubStatus === 219) {
            setCargadoAnverso(true);
            return setCargadoReverso(true);
          }
          if (res.data.idSubStatus === 248) return props.history.push("/application-complete");
        }
        if (res.status && res.data.idStatus === 4) {
          if (res.idSubStatus === 204) return props.history.push("/rejected");
          return props.history.push("/denied");
        }
        if (res.status && res.data.idStatus === 6) {
          return props.history.push("/application-complete");
        }
        if (res.status && res.data.idStatus === 7) {
          return props.history.push("/dashboard/general");
        }
        if (res.status && res.data.idStatus === 8) {
          return props.history.push("/dashboard/general");
        }
      });

      //TAG MANAGER
      getAnalytics({ idCustomer: user.customerId, idProduct }, validToken).then((analytics) => {
        if (analytics.data) {
          const tagManagerArgs = {
            dataLayer: {
              event: "pageChange",
              page: {
                url: "/dashboard/id",
                referrer: sessionStorage.getItem("utm") || "/",
              },
              client: {
                hFN: analytics.data.hFN,
                hLN: analytics.data.hLN,
                hTN: analytics.data.hTN,
                hMA: analytics.data.hMA,
                dateOfBirth: analytics.data.dateOfBirth,
                returningClient: analytics.data.returningClient,
                identifiedBy: analytics.data.identifiedBy,
                registeredBy: analytics.data.registeredBy,
              },
              loans: {
                loansCount: analytics.data.loansCount,
              },
              lastest_loan: {
                status: analytics.data.status,
                id: analytics.data.id,
                repaymentDate: analytics.data.repaymentDate,
              },
              application: {
                id: analytics.data.application.id,
              },
            },
            dataLayerName: "dataLayer",
          };
          TagManager.dataLayer(tagManagerArgs);
        }
      });

      // const {
      //   data: { documentList },
      // } = await getDocumentList(
      //   {
      //     idProduct,
      //     idCustomer: user.customerId,
      //     idAction: 1,
      //   },
      //   validToken
      // );

      const documentList = [
        { idDocument: 27, documentDescription: "Identificacion Oficial" },
        { idDocument: 30, documentDescription: "Identificación Oficial Reverso" },
        { idDocument: 39, documentDescription: "Selfie" },
      ];

      setDocumentList(documentList);

      //TAG MANAGER
    };
    let approved = sessionStorage.getItem("APP");
    if (approved === "no") return props.history.push("/denied");
    let getUser = JSON.parse(sessionStorage.getItem("loggedUser"));

    if (!getUser) {
      let emptyCustomer = JSON.parse(sessionStorage.getItem("empty-customer"));
      setUser(emptyCustomer);
      checkUser(emptyCustomer);
      return;
    }
    setUser(getUser);
    checkUser(getUser);

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    if (demoUser) return setUser(demoUser);
    askToken();
    return;
    // let getToken = cookie.load('token')
    // if(!getToken) return props.history.push('/login')
    // setToken(getToken)
  }, []);

  useEffect(() => {
    setMobile(window.innerWidth < 900);
  });

  useEffect(() => {
    setReady(documentList.reduce((prev, doc) => (prev ? doc.uploaded : false), true));
  }, [documentList]);

  useEffect(() => {
    //Número de oportunidades utilizadas para validar la identificación
    setOpportunities(parseInt(localStorage.id_opportunities) || 0);
  }, []);

  const setDebitAuth = async () => {
    let response = await getToken();
    const validToken = response.data.token;
    const myIp = await publicIp.v4();
    let coords = sessionStorage.getItem("coords");
    if (!coords) coords = "Location blocked";
    const debitData = {
      userAgent: navigator.userAgent,
      ip: myIp,
      authorized: true,
      coordinates: coords,
      idProduct,
      idCustomer: user.customerId,
    };
    await setDirectDebitAuthorization(debitData, validToken);
  };

  return (
    <>
      <Helmet>
        <title> Sube tus documentos | Vivus Mx</title>
        <meta name="title" content="Sube tus documentos | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <meta name="robots" content="noindex" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center items-center flex-1">
        <div className="container flex flex-col gap-4 p-4 ">
          <div className="flex flex-col gap-4 py-4">
            {/* <DemoButton callback={fillDemo} /> */}
            <div className="w-full flex flex-col gap-2">
              <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">¡Ya casi tienes tu préstamo! </h1>
              <h2 className="text-xl lg:text-2xl text-green-light">
                Para finalizar el proceso, solo necesitamos que nos compartas los siguientes <strong className="font-bold">documentos</strong> como son solicitados.
              </h2>
            </div>
            <div className="flex flex-col gap-4">
              <strong className={"hidden-mobile"}>IMPORTANTE:</strong> Para una correcta validación te pedimos los siguientes requisitos:
              <ul className="list-disc pl-4">
                <li>Que las fotografías de tu identificación sean a color </li>
                <li>Totalmente legibles, es decir, que se puedan leer los datos de tu credencial </li>
                <li>Sin flash </li>
                <li>Que sean visibles los bordes de tu credencial </li>
              </ul>
              <strong className="text-green-dark text-xl underline">Saca las fotos desde tu celular (no escáner, no impresiones blanco y negro, no fotos guardadas en biblioteca)</strong>
            </div>
            <div className="flex flex-col lg:flex-row gap-12 max-w-6xl flex-wrap justify-between">
              {documentList.map((document) => (
                <DocumentItem document={document} key={`document-${document.idDocument}`} validate={validateDocument} />
              ))}

              {!ready && (
                <div className="flex flex-col py-8 px-4 border-2 border-green-dark text-center justify-center items-center flex-1 max-w-lg w-full">
                  <h3 className="text-2xl font-bold">Carga tus documentos para continuar</h3>
                  <p>Verifica que los documentos que subas sean los solicitados, ya que no podremos procesar tu solicitud sin ellos.</p>
                </div>
              )}
              {ready && !idLoading && documentList.length > 0 && (
                <div className="flex flex-col py-8 px-4  gap-4 border-2 border-green-dark text-center justify-center items-center flex-1 max-w-lg w-full">
                  {!idError && (
                    <>
                      <p className="text-4xl font-bold text-green-dark">Tus documentos han sido cargados exitosamente</p>{" "}
                    </>
                  )}
                  {idError && (
                    <>
                      <p className="text-2xl font-bold text-red-500">No hemos podido verificar tu identidad</p>
                      <p className="text-gray-500">
                        Verifica y vuelve a cargar tus documentos correctamente, solo cuentas con{" "}
                        <strong className="font-bold">
                          {3 - opportunities} oportunidad
                          {opportunities < 2 && "es"}
                        </strong>
                        .
                      </p>
                    </>
                  )}
                  <p>
                    Da clic en "Continuar" {idError && "volver a "} verificar tu identidad.
                    {idError && " después de haber cargado tus documentos"}.
                  </p>

                  <MainButton className={`lg:w-56 text-center  ${!continueDisabled ? "bg-orange-400" : "bg-gray-200 cursor-default pointer-events-none"} `} onClick={handleContinueClick}>
                    CONTINUAR
                  </MainButton>
                </div>
              )}
            </div>

            {cargadoAnverso && cargadoReverso && (
              <div className="bg-green-light hover:bg-green-dark text-white font-bold p-4 flex flex-col items-center justify-center max-w-6xl">
                <h2>¿Necesitas ayuda?</h2>
                <h4>Mándanos un WhatsApp</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Identificacion);
