import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CoverSEO from "./shared/CoverSEO";
import ReviewSlider from "./shared/ReviewSlider";
import FAQ from "./shared/FAQ";

const questions = ["¿Qué documentos necesito para solicitar un crédito en línea?", "¿Cuánto tiempo tarda en llegar el dinero a mi cuenta?", "¿Puedo solicitar un crédito si tengo mal historial crediticio?"];

const answers = ["Solo necesitas una identificación oficial y datos bancarios.", "Una vez aprobada tu solicitud, el dinero se transfiere en menos de 24 horas.", "Sí, en Vivus ofrecemos créditos en línea sin buró de crédito, evaluando tu capacidad actual de pago."];

const CreditosEnLinea = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Créditos en Línea incluso si nunca has tenido uno | Vivus Mx</title>
        <meta name="title" content="Créditos en Línea incluso si nunca has tenido uno | Vivus Mx" />
        <meta name="description" content="Obtén créditos en línea rápidos y seguros con Vivus. Flexibilidad en montos y plazos, sin buró de crédito. ¡Solicita ahora y recibe tu dinero en minutos!" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="relative w-full flex justify-center flex-1 lg:pb-12 flex-col items-center text-[#65696E] mb-8">
        <CoverSEO title="Créditos en Línea: Flexibles, Seguros y Rápidos con Vivus" description="Obtén créditos en línea rápidos y seguros con Vivus. Flexibilidad en montos y plazos, sin buró de crédito. ¡Solicita ahora y recibe tu dinero en minutos!" />
        <div className="container flex flex-col gap-4 p-4">
          <img src={`/img/seo/seo_creditos_en_linea.jpg`} alt="créditos en línea" className="w-full object-cover hidden lg:block" />
          <img src={`/img/seo/seo_creditos_en_linea_sm.jpg`} alt="créditos en línea" className="w-full object-cover lg:hidden" />
          <h2 className="text-xl font-bold">Créditos en Línea: Flexibles, Seguros y Rápidos con Vivus</h2>
          <p>
            En Vivus, sabemos lo importante que es acceder a financiamiento de manera rápida y segura. Por eso, ofrecemos <b className="font-bold">créditos en línea</b> adaptados a tus necesidades, permitiéndote solicitar dinero desde cualquier lugar, en cualquier momento.
          </p>
          <h2 className="text-xl font-bold">¿Por Qué Elegir Créditos en Línea con Vivus?</h2>
          <p className="font-bold">Rápidos y Convenientes</p>
          <p>
            Con Vivus, puedes obtener{" "}
            <a href="https://www.vivus.com.mx/" className="underline text-green-dark">
              préstamos en línea
            </a>{" "}
            sin trámites complicados. Nuestro proceso es 100% digital, permitiéndote completar tu solicitud en minutos y recibir el dinero en tu cuenta rápidamente.
          </p>

          <p className="font-bold">Flexibilidad en Montos y Plazos</p>
          <p>
            Ofrecemos opciones de financiamiento variadas, desde pequeños montos hasta <b className="font-bold">préstamos urgentes</b> de 10 mil pesos, con plazos de pago que se adaptan a tus necesidades.
          </p>
          <p className="font-bold">Sin Buró de Crédito</p>
          <p>
            No te preocupes por tu historial crediticio. En Vivus, ofrecemos <b className="font-bold">préstamos en línea sin buró de crédito</b>, evaluando tu capacidad actual de pago para brindarte acceso a financiamiento sin complicaciones.
          </p>
          <p className="font-bold">Seguridad y Confianza</p>
          <p>
            La seguridad de tus datos es nuestra prioridad. Utilizamos tecnología avanzada para proteger tu información personal y garantizar que todo el proceso de solicitud sea seguro y confiable. Además, estamos regulados por la CONDUSEF asegurando que operamos bajo estrictas normativas
            financieras.
          </p>

          <h2 className="text-xl font-bold">Beneficios de los Créditos en Línea</h2>
          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Préstamos express</b>: Obtén el dinero que necesitas rápidamente y sin largas esperas.
            </li>
            <li>
              <b className="font-bold">Préstamos urgentes</b>: Ideales para cubrir gastos imprevistos o emergencias financieras.
            </li>
            <li>
              <b className="font-bold">Préstamos confiables</b>: Con más de 8 años de experiencia, Vivus ha ganado la confianza de miles de clientes.
            </li>
          </ul>
          <h2 className="text-xl font-bold">¿Cómo Solicitar un crédito en línea ?</h2>
          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Solicitud</b>: Crea tu cuenta en{" "}
              <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
                VIVUS
              </a>
              .
            </li>
            <li>
              <b className="font-bold">Registro</b>: Ingresa tus datos personales.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Tu solicitud será evaluada rápidamente, y en cuestión de minutos, recibirás una respuesta.
            </li>
            <li>
              <b className="font-bold">Depósito del dinero</b>: Si tu solicitud es aprobada, el dinero se deposita directamente en tu cuenta bancaria, listo para ser usado según tus necesidades.
            </li>
          </ol>
          <p>*Sujeto a verificación de datos y aprobación de crédito.</p>

          <h2 className="text-xl font-bold">Calcula tu crédito en línea</h2>
          <p>Utiliza nuestra Calculadora de préstamos para determinar el monto y plazo que mejor se ajusten a tus necesidades. Esta herramienta te ayudará a planificar mejor tus finanzas y a tomar decisiones informadas.</p>

          <h2 className="text-xl font-bold">Únete a Miles de Usuarios Satisfechos</h2>
          <p>Vivus se ha convertido en la opción preferida para quienes buscan créditos en línea debido a nuestra transparencia, rapidez y seguridad. No esperes más y resuelve tus necesidades financieras con nosotros.</p>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href={"https://www.vivus.com.mx/registration"}>
            Solicitar crédito en línea
          </a>

          <p>En Vivus, estamos comprometidos a ofrecerte soluciones de financiamiento que se ajusten a tu vida. Solicita tu crédito en línea hoy mismo y experimenta la diferencia de trabajar con un líder en préstamos digitales.</p>
        </div>
        <div className="w-screen">
          <ReviewSlider
            title="Testimonios de Clientes Satisfechos"
            reviews={[
              {
                rating: 5,
                text: "Solicitar un crédito en línea con Vivus fue la mejor decisión. El proceso fue rápido y sencillo, y el dinero estuvo en mi cuenta en minutos.",
                name: "Ana G.",
              },
              {
                rating: 5,
                text: "Gracias a Vivus, pude cubrir un gasto imprevisto sin complicaciones. La seguridad y la confianza que ofrecen son inigualables.",
                name: "Carlos M.",
              },
            ]}
          />
        </div>
        <div className="container flex flex-col gap-4 p-4">
          <h2 className="text-xl font-bold">Comparativa de Créditos en Línea</h2>

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b-2 border-gray-300 bg-gray-100 text-left">Característica</th>
                  <th className="px-4 py-2 border-b-2 border-gray-300 bg-gray-100 text-left">Vivus</th>
                  <th className="px-4 py-2 border-b-2 border-gray-300 bg-gray-100 text-left">Competencia</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-bold px-4 py-2 border-b border-gray-300 text-start">Tiempo de Aprobación</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-start">En minutos</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-start">Varía entre horas y días</td>
                </tr>
                <tr>
                  <td className="font-bold px-4 py-2 border-b border-gray-300 text-start">Montos Disponibles</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-start">Hasta 10 mil pesos</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-start">Limitado a montos menores</td>
                </tr>
                <tr>
                  <td className="font-bold px-4 py-2 border-b border-gray-300 text-start">Flexibilidad de Plazos</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-start">Adaptados a tus necesidades</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-start">Menos opciones flexibles</td>
                </tr>
                <tr>
                  <td className="font-bold px-4 py-2 border-b border-gray-300 text-start">Requisitos</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-start">Sin buró de crédito</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-start">Requiere buen historial</td>
                </tr>
                <tr>
                  <td className="font-bold px-4 py-2 text-start">Seguridad</td>
                  <td className="px-4 py-2 text-start">Alta, regulada por la entidad reguladora nacional</td>
                  <td className="px-4 py-2 text-start">Varía</td>
                </tr>
              </tbody>
            </table>
            <FAQ questions={questions} answers={answers} title="Preguntas Frecuentes sobre Créditos en Línea" />
            {/* <img src={`/img/seo/seo_creditos_en_linea_2.jpg`} alt="préstamos inmediatos" className="w-full" /> */}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

CreditosEnLinea.propTypes = {};
CreditosEnLinea.defaultValues = {};

export default CreditosEnLinea;
