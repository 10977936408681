import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CoverSEO from "./shared/CoverSEO";
import ReviewSlider from "./shared/ReviewSlider";
import FAQ from "./shared/FAQ";

const questions = ["¿Qué necesito para solicitar un préstamo en Vivus?", "¿Cuánto tiempo tarda en procesar mi solicitud?", "¿Puedo obtener un préstamo si tengo mal historial crediticio?"];

const answers = ["Solo necesitas una identificación oficial y una cuenta bancaria.", "La evaluación y aprobación se realizan en minutos, y el dinero se deposita en menos de 24 horas.", "Sí, en Vivus checamos pero no es un factor decisivo. Evaluamos tu capacidad actual de pago."];

const PrestamosCDMX = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Préstamos en Ciudad de México | Vivus Mx</title>
        <meta name="title" content="Préstamos en Ciudad de México | Vivus Mx" />
        <meta name="description" content="Obtén préstamos personales rápidos y confiables en la Ciudad de México con Vivus. Solicita en línea sin checar buró de crédito y recibe tu dinero en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="relative w-full flex justify-center flex-1 lg:pb-12 flex-col items-center text-[#65696E]">
        <CoverSEO title="Brindamos Préstamos en Ciudad de México" description="Obtén préstamos personales rápidos y confiables en la Ciudad de México con Vivus. Solicita en línea sin checar buró de crédito y recibe tu dinero en minutos." />
        <div className="container flex flex-col gap-4 p-4">
          <img src={`/img/seo/seo_cdmx.jpg`} alt="préstamos en ciudad de méxico" className="w-full object-cover" />
          <p>
            Si eres de Ciudad de México y estás buscando una solución financiera rápida y confiable, Vivus es tu mejor opción. Ofrecemos <b className="font-bold">préstamos personales</b> diseñados para adaptarse a tus necesidades sin complicaciones y con la máxima transparencia.
          </p>
          <h2 className="text-xl font-bold">Ventajas de los Préstamos en la Ciudad de México con Vivus</h2>

          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Rápidos y eficientes</b>: Obtén tu dinero en minutos sin tener que esperar largos procesos.
            </li>
            <li>
              <b className="font-bold">Transparencia Total</b>: Conoce todas las condiciones desde el principio, sin sorpresas ocultas.
            </li>
            <li>
              <b className="font-bold">Flexibilidad</b>: Ofrecemos opciones de financiamiento que se ajustan a tus necesidades.
            </li>
            <li>
              <b className="font-bold">Sin Buró de Crédito</b>: No importa tu historial crediticio, evaluamos tu capacidad actual de pago.
            </li>
          </ul>

          <h2 className="text-xl font-bold">Financieras Confiables en la Ciudad de México</h2>

          <p>
            Vivus se destaca entre las <b className="font-bold">financieras confiables en la Ciudad de México</b>, ofreciendo un servicio transparente y seguro. Nuestro compromiso es brindarte las mejores condiciones y un proceso sencillo para obtener tu préstamo. Si buscas financieras en la Ciudad
            de México, Vivus es tu mejor opción.
          </p>
          <p>
            No solo brindamos un proceso fluido y sin tanto papeleo como los bancos, sino que también ofrecemos un{" "}
            <a href="https://www.vivus.com.mx/" className="underline text-green-dark">
              préstamo en línea
            </a>{" "}
            al cual puedes acceder desde cualquier lugar y en el momento que desees.
          </p>

          <h2 className="text-xl font-bold">¿Cómo solicitar mi primer préstamo?</h2>

          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Solicitud</b>: Crea tu cuenta en{" "}
              <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
                VIVUS
              </a>
              .
            </li>
            <li>
              <b className="font-bold">Registro</b>: Ingresa tus datos personales.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Tu solicitud será evaluada rápidamente, y en cuestión de minutos, recibirás una respuesta.
            </li>
            <li>
              <b className="font-bold">Depósito del dinero</b>: Si tu solicitud es aprobada, el dinero se deposita directamente en tu cuenta bancaria, listo para ser usado según tus necesidades.
            </li>
          </ol>
          <p>*Sujeto a verificación de datos y aprobación de crédito.</p>

          {/* ---- */}

          <h2 className="text-xl font-bold">Beneficios de Elegir Vivus para tus Préstamos en Ciudad de México</h2>

          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Sin papeleo</b>: Todo el proceso del préstamo personal es digital, sin necesidad de trámites complicados.
            </li>
            <li>
              <b className="font-bold">Confidencialidad</b>: Tus datos están seguros con nosotros.
            </li>
            <li>
              <b className="font-bold">Flexibilidad de pago</b>: Ofrecemos opciones de pago que se adaptan a tus posibilidades y extensiones en caso de que no puedas cumplir con la fecha límite.
            </li>
          </ul>
        </div>
        <div className="w-screen">
          <ReviewSlider
            reviews={[
              {
                rating: 5,
                text: "Vivus es increíblemente rápido y confiable. Necesitaba un préstamo urgente y en minutos tenía el dinero en mi cuenta.",
                name: "Carlos M.",
              },
              {
                rating: 5,
                text: "La mejor opción en la Ciudad de México para préstamos personales. El proceso fue sencillo y sin complicaciones.",
                name: "María P.",
              },
            ]}
          />
        </div>
        <div className="container flex flex-col gap-4 p-4">
          <FAQ questions={questions} answers={answers} />

          <h2 className="text-xl font-bold text-start"> ¿Cómo Funciona la Calculadora de Préstamos?</h2>

          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Selecciona el Monto del Préstamo</b>: Crea tu cuenta en{" "}
              <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
                VIVUS
              </a>
              .
            </li>
            <li>
              <b className="font-bold">Elige el Plazo de Pago</b>: Ingresa la cantidad que deseas solicitar, que en el caso de Vivus México, varía entre 300 MXN y 10,000 MXN.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Define el periodo en el que deseas devolver el préstamo, que puede ser de 7 a 30 días.
            </li>
            <li>
              <b className="font-bold">Calcula el Costo Total</b>: La <b className="font-bold">Calculadora de Préstamos</b> te mostrará el monto total a pagar, incluyendo intereses y cualquier otra tarifa aplicable.
            </li>
          </ol>
        </div>{" "}
      </div>
    </Suspense>
  );
};

PrestamosCDMX.propTypes = {};
PrestamosCDMX.defaultValues = {};

export default PrestamosCDMX;
