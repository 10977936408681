import OneSignal from "react-onesignal";

export default async function runOneSignal() {
  await OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID ?? "000" });
  OneSignal.Slidedown.promptPush();
}

export async function sendExternalId(external_id) {
  console.log("Sending external id to OneSignal");
  await OneSignal.login(external_id + "");
}
