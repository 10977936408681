import React from "react";
import AccordionFaq from "./AccordionFaq";

const FAQ = ({ questions = [], answers = [], title = "Preguntas frecuentes" }) => {
  return (
    <div className="flex justify-center items-center flex-1 flex-col w-full">
      <div className="container px-4 py-12 flex gap-4 flex-col">
        <h2 className="lg:text-4xl text-3xl font-bold text-center">{title}</h2>
        <div className="flex flex-col gap-2">
          {questions.map((question, index) => (
            <AccordionFaq question={question} answer={answers[index]} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
