import React, { useState, useEffect } from "react";
import "./calculator.scss";
import "../register/RegisterCalculator.scss";
import Slider from "./Slider";
import { BallClipRotate } from "react-pure-loaders";
import { getToken, getConfiguration, getSimulation } from "../../services/api.js";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { momentEs } from "../../services/moment";
import { Link, withRouter } from "react-router-dom";
import { currencyFormatter, decimalFormatter } from "../../utils/format";
import Button from "../common/Button";
import { useAnalytics } from "../../hooks/use-analytics";
import { Tooltip } from "react-tooltip";

const idProduct = 1;

const Calculator = (props) => {
  const [monto, setMonto] = useState(null);
  const [firstPaymentAmount, setFirstPaymentAmount] = useState(0);
  const [plazo, setPlazo] = useState(null);
  const [periodicidad] = useState(3);
  const [interesIVA, setInteresIVA] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [cat, setCat] = useState(0);
  const [, setAmortizationTable] = useState([]);
  const [commision, setCommision] = useState(0);
  const [tkn, setToken] = useState("");
  const { dataLayerPushEvent } = useAnalytics();

  let idClient = 0;

  const [config, setConfig] = useState(null);

  const loadSimulation = () => {
    setMonto(3000);
    setPlazo(process.env.REACT_APP_SITE === "mivivus" ? 15 : 1);
    setConfig("error");
    setInteresIVA(0);
    setFecha("DD/MM/AAA");
    setFirstPaymentAmount(0);
    setCommision(120);
  };

  const initialConfiguration = async () => {
    const response = await getToken();
    if (!response) return;
    setToken(response.data.token);
    if (response.status !== 200) return loadSimulation();
    getConfiguration(idProduct, response.data.token).then((res) => {
      const { data } = res;
      if (res.status === 200) {
        // setMonto(data.defaultAmount);
        setMonto(3000);
        setPlazo(process.env.REACT_APP_SITE === "mivivus" ? 15 : data.frequencies[0].frequencyTerm.defaultValue);
        setConfig(data);
        // return simulate(data.defaultAmount, 3, process.env.REACT_APP_SITE === "mivivus" ? 15 : data.frequencies[0].frequencyTerm.defaultValue, response.data.token);
        return simulate(3000, 3, process.env.REACT_APP_SITE === "mivivus" ? 15 : data.frequencies[0].frequencyTerm.defaultValue, response.data.token);
      }
      setConfig("error");
    });
  };

  const simulate = async (amount, freq, term, token) => {
    if (!amount || amount < 1) return;
    getSimulation(idProduct, amount, freq, term, idClient, token).then((res) => {
      const { data } = res;
      if (res.status === 200) {
        setInteresIVA(data.interest);
        setFecha(data.firstDueDate);
        setFirstPaymentAmount(data.firstPaymentAmount);
        setAmortizationTable(data.amortizationTable);
        setCat(data.cat);
        return setCommision(data.commision);
      }
    });
  };

  const updateMonto = (val) => {
    setMonto(val);
  };

  const updatePlazo = (val) => {
    setPlazo(process.env.REACT_APP_SITE === "mivivus" ? 15 : val);
  };

  const setData = () => {
    sessionStorage.setItem("proposal", JSON.stringify({ idProduct, monto, periodicidad, plazo }));
    dataLayerPushEvent("Begin_Registration");
    if (typeof window !== "undefined") {
      if (window.top !== window) {
        window.top.location.href = "https://www.vivus.com.mx/registration";
        return;
      }
    }
    if (sessionStorage.getItem("proposal")) return props.history.push("/registration");
  };

  const validSimulate = () => {
    if (config !== null && config !== "error") simulate(monto, periodicidad, plazo, tkn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    initialConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {config === "error" ? (
        <div className="rounded-xl overflow-hidden bg-white flex flex-col shadow-sm h-auto lg:w-96 w-full">
          <div className="bg-green-light h-16 flex justify-center items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
          <div className="p-8">
            <h2 className="text-center text-xl">Servidor no disponible, intenta más tarde.</h2>
          </div>
        </div>
      ) : config !== null && fecha !== null ? (
        <div className="rounded-xl overflow-hidden bg-white flex flex-col shadow-sm h-auto lg:w-96 text-sm w-full">
          <div className="bg-green-light flex justify-center items-center flex-col py-4 px-8 xl:p-8 gap-4">
            <div className="w-full flex flex-col">
              <div className="flex justify-space w-full justify-between items-center">
                <p className="text-white font-bold text-lg">Monto total</p>
                <span className="text-white text-2xl xl:text-4xl font-bold">${decimalFormatter.format(monto)}</span>
              </div>
              <Slider config={config} min={config.minAmount} max={config.maxAmount} step={config.stepAmount} value={monto} labelFormatter={currencyFormatter} update={updateMonto} validSimulate={validSimulate} />
              {monto > 3000 && <div className="w-full text-gray-600 mt-6 bg-white p-2 text-xs text-center rounded-xl transition-all font-bold">¿Eres cliente nuevo y necesitas un monto mayor a $3,000? Aumenta el límite en tu próximo préstamo.</div>}
            </div>
            {process.env.REACT_APP_SITE !== "mivivus" && (
              <div className="w-full">
                <div className="w-full h-px bg-white mt-4" />
                <div className="flex justify-space w-full justify-between items-center mt-6">
                  <p className="text-white font-bold text-lg">Plazo</p>
                  <span className="text-white text-2xl xl:text-4xl font-bold">{plazo} días</span>
                </div>
                <Slider
                  config={config}
                  min={config !== "error" ? (periodicidad === 2 ? config.frequencies[1].frequencyTerm.minTerm : config.frequencies[0].frequencyTerm.minTerm) : 0}
                  max={config !== "error" ? (periodicidad === 2 ? config.frequencies[1].frequencyTerm.maxTerm : config.frequencies[0].frequencyTerm.maxTerm) : 0}
                  step={1}
                  value={plazo}
                  labelFormatter={decimalFormatter}
                  update={updatePlazo}
                  validSimulate={validSimulate}
                />
              </div>
            )}
            <div className="py-2 mx-4 mt-4 flex lg:hidden w-full">
              <Button className="w-full bg-white !text-green-dark" onClick={setData}>
                Solicítalo ya
              </Button>
            </div>
          </div>
          <div className="px-8 py-4">
            <div className="flex items-center justify-between py-2">
              <p>Interés</p>
              <div>
                <p>
                  {interesIVA.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
                <p className="text-xs text-right">IVA incluído</p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center justify-between py-2">
              <p className="text-left w-1/2">Comisiones</p>
              <div>
                <p className="text-right">
                  {commision.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
                <p className="text-right text-xs">IVA incluído</p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center justify-between py-2">
              <p>
                <strong>Monto a pagar</strong>
              </p>
              <div>
                <p className="text-green-dark">
                  <strong>
                    {firstPaymentAmount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    <small> MXN</small>
                  </strong>
                </p>
                <p className="text-right text-xs">IVA incluído</p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center justify-between pt-2 pb-4">
              <p className="text-left">{process.env.REACT_APP_SITE === "mivivus" ? <strong>Primera fecha de pago</strong> : <strong>Fecha de pago</strong>}</p>
              <p className="text-green-dark">
                <strong>{momentEs(fecha).format("D/MMM/Y")}</strong>
              </p>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center justify-between pt-2 pb-4">
              <p className="text-left">CAT promedio</p>
              <div className="flex flex-col">
                <p className="">
                  {cat.toLocaleString("en-US", {
                    style: "decimal",
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 1,
                  })}
                  %
                </p>
                <p className="text-right text-xs">Sin IVA</p>
              </div>
            </div>
            {/* <div className="text-xs text-left py-4">
              <b>CAT: {cat}%</b> promedio sin IVA para fines informativos y de comparación exclusivamente. <br />
              Fecha de cálculo: {momentEs(today).format("D/MMM/Y")}
              <br />
              <br />
              Calculadora para fines informativos y de comparación. <br />
              <br />
              Nunca solicitamos anticipos, cuotas, pago de pólizas o cualquier otro concepto para otorgarte un préstamo.
            </div> */}
            <div className="py-2 hidden lg:flex">
              <Button className="w-full" onClick={setData}>
                Solicítalo ya
              </Button>
            </div>
            <div className="flex justify-center py-2">
              <Link to="/login" target="_parent" className="underline cursor-pointer text-green-dark hover:text-green-light transition-all">
                ¿Ya tienes cuenta?
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="rounded-xl mt-24 overflow-hidden bg-white flex flex-col shadow-sm h-auto lg:w-96 w-full">
          <div className="bg-green-light flex justify-center items-center flex-col h-16">
            <BallClipRotate loading color={"white"} />
          </div>
          <div className="p-8">
            <h2 className="text-center text-xl">Cargando datos...</h2>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(Calculator);
