import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Calculator from "../../calculator/Calculator";
import "../../../index.css";
import "../../../App.css";

const CoverSEO = ({ title = "Préstamos Personales en línea, fácil aprobación", description = "", backgroundImage = "/img/landing/cover-seo.jpg" }) => {
  const [monthName, setMonthName] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="w-full flex flex-col items-center lg:flex-row lg:items-start justify-center relative lg:bg-coverSEO lg:bg-center xl:pb-12 lg:min-h-lgcover xl:min-h-cover mb-8">
      {/* <img src="/img/landing/Préstamo-personales-en-linea.webp" alt="logo" width={300} className="absolute bottom-0 hidden translate-x-28 lg:block xl:hidden left-8" /> */}
      <div className="absolute w-full pointer-events-none h-full block -z-10 bg-cover bg-top top-0" style={{ backgroundImage: `url(${backgroundImage})` }} />

      <div className="container self-center flex flex-col lg:flex-row py-4 gap-8 justify-center lg:justify-between lg:items-center xl:items-center px-4 -pt-4 z-10 lg:py-8">
        <div className="flex flex-col max-w-3xl gap-4 py-4">
          <h1 className="text-3xl lg:text-4xl xl:text-6xl font-bold text-white lg:mt-0 flex">{title}</h1>
          <p className="text-xl lg:text-2xl text-white flex gap-4 items-center my-2">{description}</p>
        </div>
        <Calculator home={true} />
      </div>
    </section>
  );
};

export default CoverSEO;
