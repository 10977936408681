import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CoverSEO from "./shared/CoverSEO";
import ReviewSlider from "./shared/ReviewSlider";
import FAQ from "./shared/FAQ";

const questions = [
  "¿Cómo Solicitar mi Reporte de Crédito Especial?",
  "¿Qué Hacer si Encuentro un Error en mi Reporte de Crédito?",
  "¿Cuánto Tiempo Permanece la Información Negativa en mi Historial Crediticio?",
  "¿Cómo Puedo Mejorar mi Score Crediticio?",
  "¿Es Seguro Consultar mi Buró de Crédito en Línea?",
];

const answers = [
  "Para solicitar tu reporte de crédito especial, visita el sitio oficial del buró de crédito y sigue las instrucciones proporcionadas. Este servicio es gratuito una vez al año.",
  "Si encuentras un error en tu reporte de crédito, contacta al buró de crédito inmediatamente para iniciar el proceso de corrección. Proporciona cualquier evidencia que respalde tu solicitud.",
  "La información negativa puede permanecer en tu historial crediticio por hasta seis años, dependiendo del tipo de deuda y las políticas del buró de crédito. Es importante mantener un buen comportamiento crediticio para minimizar el impacto de cualquier información negativa.",
  "Puedes mejorar tu score crediticio realizando pagos puntuales, reduciendo tus deudas y utilizando tu crédito de manera responsable. Mantén un bajo saldo en tus tarjetas de crédito y evita solicitar demasiados créditos en un corto período.",
  "Sí, es seguro consultar tu buró de crédito en línea si utilizas el sitio web oficial del buró de crédito o servicios autorizados. Asegúrate de que la conexión sea segura y de proteger tus datos personales.",
];

const HistorialCrediticio = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Tu historial crediticio y sus secretos | Vivus Mx</title>
        <meta name="title" content="Tu historial crediticio y sus secretos | Vivus Mx" />
        <meta name="description" content="Consulta tu historial crediticio y mejora tu score crediticio con Vivus. Aprende cómo checar tu buró de crédito gratis y rápido. " />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="relative w-full flex justify-center flex-1 lg:pb-12 flex-col items-center text-[#65696E] mb-8">
        <CoverSEO title="Historial Crediticio: Todo lo que Necesitas Saber para Mantener tus Finanzas en Orden" description="Consulta tu historial crediticio y mejora tu score crediticio con Vivus. Aprende cómo checar tu buró de crédito gratis y rápido. " />
        <div className="container flex flex-col gap-4 p-4 max-w-[1200px]">
          <img src={`/img/seo/seo_historial_crediticio.jpg`} alt="historial crediticio" className="w-full object-cover hidden lg:block" />
          <img src={`/img/seo/seo_historial_crediticio_sm.jpg`} alt="historial crediticio" className="w-full object-cover lg:hidden" />

          <p>El historial crediticio es un registro de tu comportamiento financiero. Incluye información sobre tus créditos, pagos, y deudas. Este historial es gestionado por empresas especializadas que proporcionan informes a instituciones financieras.</p>
          <h2 className="text-xl font-bold">¿Qué es el Historial Crediticio y por Qué es Importante?</h2>
          <p>
            El historial crediticio es un registro detallado de cómo has manejado tus créditos y deudas a lo largo del tiempo. Incluye información sobre tus pagos puntuales, atrasos, montos adeudados y tipos de créditos que has utilizado. Este registro es fundamental para las instituciones
            financieras, ya que les permite evaluar tu comportamiento crediticio y determinar tu elegibilidad para futuros préstamos.
          </p>

          <h2 className="text-xl font-bold">¿Cómo Consultar tu Historial Crediticio?</h2>

          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Accede al Sitio Web del Buró de Crédito</b>: Para obtener tu reporte de crédito, visita el{" "}
              <a href="https://www.burodecredito.com.mx/reporte-info.html" className="underline text-green-dark" rel="nofollow" target="_blank">
                sitio oficial del Buró de Crédito{" "}
              </a>
              . Aquí puedes solicitar tu <b className="font-bold">reporte de crédito especial</b> una vez al año de forma gratuita. Este reporte te permitirá revisar toda la información registrada sobre ti y asegurarte de que esté correcta.
            </li>

            <li>
              <b className="font-bold">Regístrate y Proporciona tus Datos Personales</b>: Crea una cuenta en la plataforma del Buró de Crédito y proporciona la información necesaria para verificar tu identidad. Este proceso suele ser sencillo y rápido, y te permitirá acceder a tu historial
              crediticio en pocos minutos.
            </li>

            <li>
              <b className="font-bold">Descarga y Revisa tu Reporte</b>: Una vez completado el registro, podrás descargar tu reporte de crédito. Este documento incluye tu score crediticio, detalles de tus cuentas actuales y pasadas, pagos realizados, y cualquier incumplimiento. Revísalo
              cuidadosamente para asegurarte de que toda la información sea precisa.
            </li>
          </ol>

          <h2 className="text-xl font-bold">Entendiendo tu Score Crediticio</h2>
          <p>
            El <b className="font-bold">score crediticio</b> es una puntuación que refleja tu riesgo crediticio basado en tu historial. Este puntaje es utilizado por las instituciones financieras para evaluar la probabilidad de que pagues tus deudas. Un score alto indica un menor riesgo, lo que
            puede facilitar la obtención de créditos y mejores condiciones. Mantener un buen score implica realizar pagos puntuales, mantener tus deudas bajo control y no exceder tu capacidad de crédito.
          </p>

          <h2 className="text-xl font-bold">Cómo Checar tu Buró de Crédito Desde tu Celular</h2>
          <p>Hoy en día, puedes acceder a tu historial crediticio desde tu celular usando aplicaciones autorizadas. Estas apps te permiten checar tu buró de crédito de manera rápida y segura, proporcionándote acceso inmediato a tu reporte de crédito en cualquier momento y lugar.</p>

          <h2 className="text-xl font-bold">Beneficios de Conocer tu Historial Crediticio</h2>
          <p className="font-bold">Mejora tu Score Crediticio</p>
          <p>Mantener un buen historial crediticio te ayuda a mejorar tu score, lo que puede abrirte puertas a mejores ofertas de crédito y tasas de interés más bajas. Esto es crucial para planificar tus finanzas y alcanzar tus objetivos financieros.</p>

          <p className="font-bold">Prevención de Fraudes</p>
          <p>Revisar tu historial crediticio regularmente te permite detectar posibles fraudes o errores. Si encuentras información incorrecta, puedes tomar medidas para corregirla y proteger tu identidad financiera.</p>

          <p className="font-bold">Planificación financiera</p>
          <p>Conocer tu estado financiero te ayuda a planificar mejor tus gastos y deudas. Al entender tu historial crediticio, puedes tomar decisiones informadas y mantener tus finanzas en orden.</p>

          <h2 className="text-xl font-bold">Beneficios de Solicitar un Préstamo en Vivus para tu Historial Crediticio</h2>

          <p className="font-bold">Establecimiento de Historial Crediticio</p>
          <p>
            Si no tienes historial crediticio, solicitar un{" "}
            <a href="https://www.vivus.com.mx" className="underline text-green-dark">
              préstamo en línea
            </a>{" "}
            con Vivus puede ser una excelente manera de comenzar a construir uno. Al recibir y pagar un préstamo, las instituciones financieras pueden ver tu capacidad y responsabilidad para manejar el crédito.
          </p>

          <p className="font-bold">Mejoras en tu Score Crediticio</p>
          <p>
            Tu <b className="font-bold">score crediticio</b> es una parte crucial de tu historial financiero. Solicitar y pagar puntualmente un préstamo en Vivus puede mejorar tu score, lo que te hará más atractivo para futuros créditos. Los pagos puntuales y la gestión adecuada de tu crédito
            muestran a las instituciones financieras que eres un prestatario confiable.
          </p>

          <p className="font-bold">Diversificación de Tipos de Crédito</p>
          <p>
            Tener una variedad de tipos de crédito, como préstamos personales y tarjetas de crédito, puede fortalecer tu historial crediticio. Al añadir un préstamo de Vivus a tu reporte, demuestras tu capacidad para manejar diferentes formas de crédito, lo cual facilita la aprobación al momento de
            hacer una{" "}
            <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
              solicitud de crédito
            </a>
            .
          </p>

          <h2 className="text-xl font-bold">¿Cómo solicitar un dinero para pagar mi préstamo?</h2>
          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Solicitud</b>: Crea tu cuenta en{" "}
              <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
                VIVUS
              </a>
              .
            </li>
            <li>
              <b className="font-bold">Registro</b>: Ingresa tus datos personales.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Tu solicitud será evaluada rápidamente, y en cuestión de minutos, recibirás una respuesta.
            </li>
            <li>
              <b className="font-bold">Depósito del dinero</b>: Si tu solicitud es aprobada, el dinero se deposita directamente en tu cuenta bancaria, listo para ser usado según tus necesidades.
            </li>
          </ol>
          <p>*Sujeto a verificación de datos y aprobación de crédito.</p>

          <FAQ questions={questions} answers={answers} title="Preguntas Frecuentes sobre Historial Crediticio" />

          <p>
            Solicitar un préstamo en Vivus puede ser una herramienta poderosa para mejorar o establecer tu historial crediticio. Con pagos puntuales y una gestión adecuada del crédito, puedes mostrar a las instituciones financieras que eres un prestatario responsable. Solicita tu crédito en línea
            con Vivus y empieza a construir un futuro financiero más sólido.
          </p>
        </div>
      </div>
    </Suspense>
  );
};

HistorialCrediticio.propTypes = {};
HistorialCrediticio.defaultValues = {};

export default HistorialCrediticio;
