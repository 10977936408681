import React, { useState, useEffect } from "react";
import "./RegisterCalculator.scss";
import { BallClipRotate } from "react-pure-loaders";
import { getToken, getSimulation, getConfiguration } from "../../services/api.js";
import cookie from "react-cookies";
import { momentEs } from "../../services/moment";
import { withRouter, Link } from "react-router-dom";
import { PopInformation } from "../register/PopInformation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { currencyFormatter, decimalFormatter } from "../../utils/format";
import Slider from "../calculator/Slider";
import Button from "../common/Button";
import { Tooltip } from "react-tooltip";
// import { faTable, faDownload } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const idProduct = 1;

const RegisterCalculator = ({ proposalChange = {}, props }) => {
  const [open, setOpen] = useState(false);
  const [monto, setMonto] = useState(null);
  const [firstPaymentAmount, setFirstPaymentAmount] = useState(null);
  const [plazo, setPlazo] = useState(null);
  const [periodicidad, setPeriodicidad] = useState(3);
  const [cat, setCat] = useState(null);
  const [interesIVA, setInteresIVA] = useState(null);
  const [commision, setCommision] = useState(0);
  const [fecha, setFecha] = useState(null);
  const [registerData, setRegisterData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [amortizationTable, setAmortizationTable] = useState([]);
  const [serverError, setServerError] = useState(false);

  let today = new Date();
  let idClient = 0;

  const simulate = async (amount, freq, term) => {
    let validToken = await cookie.load("token");
    if (!validToken) {
      let response = await getToken();
      if (!response.data) return props.push("/error");
      if (response.data) validToken = response.data.token;
    }
    getSimulation(idProduct, amount, freq, term, idClient, validToken)
      .then((res) => {
        const { data } = res;
        if (res.status === 200) {
          setCat(data.cat);
          setInteresIVA(data.interest);
          setFirstPaymentAmount(data.firstPaymentAmount);
          setFecha(data.firstDueDate);
          setLoading(false);
          setAmortizationTable(data.amortizationTable);
          setCommision(data.commision);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateMonto = (val) => {
    setMonto(val);
  };

  const updatePlazo = (val) => {
    setPlazo(process.env.REACT_APP_SITE === "mivivus" ? 15 : val);
  };

  // const updatePeriodicidad = val => {
  //     if(val === 2){
  //         if(plazo > 6) setPlazo(6)
  //     }
  //     if(val === 1){
  //         if(plazo < 4) setPlazo(4)
  //     }
  //     setPeriodicidad(val)
  // }

  const getInitConfig = async () => {
    let validToken = await cookie.load("token");
    if (!validToken) {
      let response = await getToken();
      if (!response.data) return props.push("/error");
      if (response.data) validToken = response.data.token;
    }
    getConfiguration(idProduct, validToken)
      .then((res) => {
        const { data } = res;
        if (res.status === 200) {
          let config = {
            minAmount: data.minAmount,
            maxAmount: data.maxAmount > 3000 ? 3000 : data.maxAmount,
            stepAmount: data.stepAmount,
            minTermSem: data.frequencies[0].frequencyTerm.minTerm,
            maxTermSem: data.frequencies[0].frequencyTerm.maxTerm,
            minTermQuin: data.frequencies[1] ? data.frequencies[1].frequencyTerm.minTerm : 0,
            maxTermQuin: data.frequencies[1] ? data.frequencies[1].frequencyTerm.maxTerm : 0,
          };
          setRegisterData({ sliderConfig: config });
          setMonto(data.defaultAmount > 3000 ? 3000 : data.defaultAmount);
          const realTerm = process.env.REACT_APP_SITE === "mivivus" ? 15 : data.frequencies[0].frequencyTerm.defaultValue;
          setPlazo(realTerm);
          simulate(data.defaultAmount > 3000 ? 3000 : data.defaultAmount, 3, realTerm);
          sessionStorage.setItem(
            "proposal",
            JSON.stringify({
              idProduct,
              monto: data.defaultAmount > 3000 ? 3000 : data.defaultAmount,
              periodicidad: 3,
              plazo: realTerm,
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const calcConfig = async () => {
    let validToken = await cookie.load("token");
    if (!validToken) {
      let response = await getToken();
      if (!response.data) return props.push("/error");
      if (response.data) validToken = response.data.token;
    }
    getConfiguration(idProduct, validToken)
      .then((res) => {
        const { data } = res;
        if (res.status === 200) {
          let config = {
            minAmount: data.minAmount,
            maxAmount: data.maxAmount > 3000 ? 3000 : data.maxAmount,
            stepAmount: data.stepAmount,
            minTermSem: data.frequencies[0].frequencyTerm.minTerm,
            maxTermSem: data.frequencies[0].frequencyTerm.maxTerm,
            minTermQuin: data.frequencies[1] ? data.frequencies[1].frequencyTerm.minTerm : 0,
            maxTermQuin: data.frequencies[1] ? data.frequencies[1].frequencyTerm.maxTerm : 0,
          };
          setRegisterData({ sliderConfig: config });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const loadConfig = async () => {
      let response = await getToken();
      if (!response.data) return setServerError(true);
      let getLocalProposal = await JSON.parse(sessionStorage.getItem("proposal"));
      if (!getLocalProposal) return getInitConfig();
      calcConfig();
      setMonto(getLocalProposal.monto > 3000 ? 3000 : getLocalProposal.monto);
      setPlazo(process.env.REACT_APP_SITE === "mivivus" ? 15 : getLocalProposal.plazo);
      setPeriodicidad(getLocalProposal.periodicidad);
      return simulate(getLocalProposal.monto > 3000 ? 3000 : getLocalProposal.monto, getLocalProposal.periodicidad, process.env.REACT_APP_SITE === "mivivus" ? 15 : getLocalProposal.plazo);
    };
    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && registerData) {
      simulate(monto, periodicidad, plazo);
      sessionStorage.setItem("proposal", JSON.stringify({ monto, periodicidad, plazo, idProduct }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monto, periodicidad, plazo]);

  useEffect(() => {
    if (proposalChange) {
      setMonto(proposalChange.monto);
      setPlazo(process.env.REACT_APP_SITE === "mivivus" ? 15 : proposalChange.plazo);
      setPeriodicidad(proposalChange.periodicidad);
      simulate(proposalChange.monto, proposalChange.periodicidad, process.env.REACT_APP_SITE === "mivivus" ? 15 : proposalChange.plazo);
      sessionStorage.setItem(
        "proposal",
        JSON.stringify({
          monto: proposalChange.monto,
          periodicidad: proposalChange.periodicidad,
          plazo: proposalChange.plazo,
          idProduct,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalChange]);

  let stepWidth = 25;
  return (
    <>
      {serverError ? (
        <div className="lg:rounded-xl overflow-hidden bg-white flex flex-col shadow-sm h-auto lg:w-full w-full">
          <div className="bg-green-light h-16 flex justify-center items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
          <div className="p-8">
            <h2 className="text-center text-xl">Servidor no disponible, intenta más tarde.</h2>
          </div>
        </div>
      ) : !loading && registerData ? (
        <div className="lg:rounded-xl overflow-hidden bg-white flex flex-col shadow-sm h-auto lg:w-full text-sm w-full border border-gray-100">
          <div className="bg-green-light flex justify-center items-center flex-col p-8 gap-4">
            <div className="flex items-center w-full justify-between">
              <div className="flex flex-col text-white">
                <h2 className="text-xl">
                  Resumen
                  <br />
                  <span className="text-2xl font-bold">del préstamo</span>
                </h2>
              </div>

              <Button className="bg-white !text-green-light hover:text-white" onClick={() => setEdit(!edit)}>
                {edit ? "Actualizar" : "Editar"}
              </Button>
            </div>
            {edit && (
              <>
                <div className="w-full flex flex-col">
                  <div className="flex justify-space w-full justify-between items-center">
                    <p className="text-white font-bold text-lg">Monto total</p>
                    <span className="text-white text-4xl font-bold">${decimalFormatter.format(monto)}</span>
                  </div>
                  <Slider config={registerData.sliderConfig} min={registerData.sliderConfig.minAmount} max={registerData.sliderConfig.maxAmount} step={registerData.sliderConfig.stepAmount} value={monto} labelFormatter={currencyFormatter} update={updateMonto} />
                  {monto > 3000 && <div className="w-full text-gray-600 mt-6 bg-white p-2 text-xs text-center rounded-xl transition-all font-bold">¿Eres cliente nuevo y necesitas un monto mayor a $3,000? Aumenta el límite en tu próximo préstamo.</div>}
                </div>
                {process.env.REACT_APP_SITE !== "mivivus" && (
                  <div className="w-full">
                    <div className="w-full h-px bg-white mt-10" />
                    <div className="flex justify-space w-full justify-between items-center mt-6">
                      <p className="text-white font-bold text-lg">Plazo</p>
                      <span className="text-white text-4xl font-bold">{plazo} días</span>
                    </div>
                    <Slider
                      config={registerData.sliderConfig}
                      min={registerData.sliderConfig !== "error" ? (periodicidad === 2 ? registerData.sliderConfig.minTermQuin : registerData.sliderConfig.minTermSem) : 0}
                      max={registerData.sliderConfig !== "error" ? (periodicidad === 2 ? registerData.sliderConfig.maxTermQuin : registerData.sliderConfig.maxTermSem) : 0}
                      step={1}
                      value={plazo}
                      labelFormatter={decimalFormatter}
                      update={updatePlazo}
                      // validSimulate={validSimulate}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="px-8 py-4">
            {!edit && (
              <>
                <div className="flex justify-between py-3">
                  <p>Préstamo</p>
                  <p className="font-bold">
                    {monto.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    <small> MXN</small>
                  </p>
                </div>
                {process.env.REACT_APP_SITE !== "mivivus" && (
                  <>
                    <div className="w-full h-px bg-gray-200" />
                    <div className="flex justify-between py-3">
                      <p>Plazo</p>
                      <p className="font-bold">
                        {plazo} {periodicidad === 2 ? "días" : "días"}
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center justify-between py-2">
              <p>Interés</p>
              <div>
                <p>
                  {interesIVA.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
                <p className="text-xs text-right">IVA incluído</p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center justify-between py-2">
              <p className="text-left w-1/2">Comisiones</p>
              <div>
                <p className="text-right">
                  {commision.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
                <p className="text-right text-xs">IVA incluído</p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center justify-between py-2">
              <p>
                <strong>Monto a pagar</strong>
              </p>
              <div>
                <p className="text-green-dark">
                  <strong>
                    {firstPaymentAmount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    <small> MXN</small>
                  </strong>
                </p>
                <p className="text-right text-xs">IVA incluído</p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center justify-between pt-2 pb-4">
              <p className="text-left">{process.env.REACT_APP_SITE === "mivivus" ? <strong>Primera fecha de pago</strong> : <strong>Fecha de pago</strong>}</p>
              <p className="text-green-dark">
                <strong>{momentEs(fecha).format("D/MMM/Y")}</strong>
              </p>
            </div>
            <div className="w-full h-px bg-gray-200" />

            <div className="flex items-center justify-between pt-2 pb-4">
              <p className="text-left">CAT promedio</p>
              <div className="flex flex-col">
                <p className="">
                  {cat.toLocaleString("en-US", {
                    style: "decimal",
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 1,
                  })}
                  %
                </p>
                <p className="text-right text-xs">Sin IVA</p>
              </div>
            </div>
            <div className="flex-col gap-4">
              <h3 className="text-xl"> Para calificar, necesitas</h3>
              <ul>
                <li className="text-md flex gap-4 items-center">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Ser de nacionalidad mexicana.</span>
                </li>
                <li className="text-md flex gap-4 items-center">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Ser mayor de 18 años.</span>
                </li>
                <li className="text-md flex gap-4 items-center">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Indicar una cuenta bancaria a tu nombre para recibir tu dinero.</span>
                </li>
                <li className="text-md flex gap-4 items-center">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Tener un teléfono celular y una cuenta de correo electrónico.</span>
                </li>
              </ul>
            </div>
            <div className="flex justify-center items-center py-4">
              <img src="/img/digicert.png" alt="digicert" />
            </div>
            <div className="flex justify-center items-center py-2">
              <Link className="underline text-green-dark hover:text-green-light transition-all" to="/contenido/aviso-de-privacidad">
                Aviso de privacidad
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="rounded-xl mt-24 overflow-hidden bg-white flex flex-col shadow-sm h-auto w-full">
          <div className="bg-green-light flex justify-center items-center flex-col h-16">
            <BallClipRotate loading color={"white"} />
          </div>
          <div className="p-8">
            <h2 className="text-center text-xl">Cargando datos...</h2>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(RegisterCalculator);
