import React, { useState, useEffect, useReducer } from "react";
import { Link, withRouter } from "react-router-dom";
import "./newStyles.scss";
import { BallBeat } from "react-pure-loaders";
import cookie from "react-cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { getAnalytics, getToken, login } from "../../services/api";
import publicIp from "public-ip";
import TagManager from "react-gtm-module";
import DemoButton from "../common/DemoButton";
import { useLogin } from "../../hooks/login";
import Button from "../common/Button";
import CheckBox from "../common/checkbox";
import bg from "../../assets/img/login/background.jpg";
import { Helmet } from "react-helmet";
import { useLoginPopUp } from "../../hooks/use-login-popup";
import { useBalancerApi } from "../../hooks/use-balancer-api";

const idProduct = 1;

const NewLogin = (props) => {
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [, setCustomerId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [passError, setPassError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [cleanLogin, setCleanLogin] = useState(false);
  const [remember, toggleRemember] = useReducer((value) => !value, true);

  const { checkExistence, storeData, isCandidate: isCandidateAPI } = useBalancerApi();

  const { setIsRepeater } = useLoginPopUp();

  const [emailError, setEmailError] = useState(false);

  const { checkStatus } = useLogin();

  let fillDemo = () => {
    setEmail("demo@demo.com");
    setPassword("parole1");
  };

  let handleEnterKey = (e) => {
    if (e.key === "Enter") return handleSubmit();
    return;
  };

  let demoContinue = () => {
    sessionStorage.setItem(
      "loggedUser",
      JSON.stringify({
        customerId: 8330,
        eMail: "demo@demo.com",
        fullName: "DEMO",
        mobile: "5565829661",
      })
    );
    return props.history.push("/dashboard/general");
  };

  const handleSubmit = async () => {
    if (email === "demo@demo.com") return demoContinue();
    setLoading(true);
    if (remember) {
      localStorage.rememberEmail = email;
    } else {
      localStorage.removeItem("rememberEmail");
    }

    let rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email || !rex.test(email.toLowerCase())) {
      setLoading(false);
      return setEmailError(true);
    }
    setEmailError(false);
    if (!password || password.length < 1) {
      setLoading(false);
      return setPassError(true);
    }
    setPassError(false);
    const myIp = await publicIp.v4();
    if (myIp) {
      sessionStorage.setItem("ip", myIp);
      let userAgent = window.navigator.userAgent;
      let coordinates;
      navigator.geolocation.getCurrentPosition(function (position) {
        coordinates = `${position.coords.latitude} ${position.coords.longitude}`;
        sessionStorage.setItem("coords", `${position.coords.latitude} ${position.coords.longitude}`);
      });
      let submittedData = {
        idProduct,
        userName: email,
        password,
        ipAddress: myIp,
        userAgent,
        coordinates,
      };
      let response = await getToken();
      if (!response.data) {
        return props.history.push("/error");
      }
      if (response.data) {
        let validToken = response.data.token;
        cookie.save("token", response.data.token, { maxAge: 60 * 20 });
        return login(submittedData, validToken).then(async (res) => {
          const { data } = res;
          if (res.status === 200) {
            let isCandidate = await checkExistence(email);
            if (isCandidate) {
              const storeResponse = await storeData(data.customer.customerId, email, password);
              if (storeResponse) {
                window.location.href = storeResponse;
                return;
              }
            }
            sessionStorage.setItem("loggedUser", JSON.stringify(data.customer));
            getAnalytics({ idCustomer: data.customer.customerId, idProduct }, validToken).then((res) => {
              if (res.data.returningClient === "RETURNING" || res.data.returningClient === "RETURNINGSLEEPER") {
                setIsRepeater(true);
              }

              return checkStatus(data.customer.customerId, validToken, myIp, email, props.history, cleanLogin, setServerError, setPassError, setLoading, password);
            });
            return;
          }
          setLoading(false);
          return setPassError(true);
        });
      }
    }
    sessionStorage.setItem("ip", "IP blocked");
    let userAgent = window.navigator.userAgent;
    let coordinates;
    navigator.geolocation.getCurrentPosition(function (position) {
      coordinates = `${position.coords.latitude} ${position.coords.longitude}`;
      sessionStorage.setItem("coords", `${position.coords.latitude} ${position.coords.longitude}`);
    });
    let submittedData = {
      idProduct,
      userName: email,
      password,
      ipAddress: myIp,
      userAgent,
      coordinates,
    };
    let response = await getToken();
    if (!response.data) {
      return props.history.push("/error");
    }
    if (response.data) {
      let validToken = response.data.token;
      cookie.save("token", response.data.token, { maxAge: 60 * 20 });
      return login(submittedData, validToken).then((res) => {
        const { data } = res;
        if (res.status === 200) {
          sessionStorage.setItem("loggedUser", JSON.stringify(data.customer));
          return checkStatus(data.customer.customerId, validToken, myIp, email, props.history, cleanLogin, setServerError, setPassError, setLoading);
        }
        setLoading(false);
        return setPassError(true);
      });
    }
  };

  useEffect(() => {
    const checkRegister = async () => {
      let recoveryMail = await sessionStorage.getItem("recoveryEmail");
      if (recoveryMail) return setEmail(recoveryMail);

      let comesFromRegister = await JSON.parse(sessionStorage.getItem("customer-register"));
      if (!comesFromRegister) {
        setCleanLogin(true);
        if (sessionStorage.getItem("loggedUser") && cookie.load("token")) props.history.push("/dashboard/general");
        return;
      }
      setCustomerId(comesFromRegister.customerId);
      return setEmail(comesFromRegister.eMail);
    };
    checkRegister();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TAG MANAGER
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "pageChange",
        page: {
          url: "/login",
          referrer: "/",
        },
      },
      dataLayerName: "dataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  // TAG MANAGER END

  // Setting remember data
  useEffect(() => {
    if (localStorage.getItem("rememberEmail")) {
      setEmail(localStorage.getItem("rememberEmail"));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Iniciar sesión | Vivus Mx</title>
        <meta name="title" content="Iniciar sesión | Vivus Mx" />
        <meta name="description" content="Inicia sesión en Vivus México y obtén tu préstamo en línea en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="flex justify-center items-center flex-1 flex-col bg-white bg-cover lg:bg-blend-normal bg-blend-lighten" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container px-4 py-12 flex gap-4 flex-col lg:flex-row relative">
          <DemoButton callback={fillDemo} />
          <div className="w-full lg:w-1/2 xl:w-1/3 flex flex-col gap-8">
            <div className="w-full flex flex-col gap-4">
              <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Bienvenido de nuevo</h1>
              <h2 className="text-2xl lg:text-4xl text-green-light">Ingresa a tu cuenta Vivus</h2>
            </div>
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-2">
                <label className="font-bold">Correo electrónico</label>
                <input className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm" onChange={(e) => setEmail(e.target.value)} type="email" name="email" value={email} />
                {emailError ? <span className="text-red-500">Ingresa un correo válido</span> : null}
              </div>
              <div className="flex flex-col gap-2">
                <label className="font-bold">Contraseña</label>
                <div className="flex flex-col gap-2">
                  <div className="relative w-full flex items-center">
                    <input value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={handleEnterKey} className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm" type={showPassword ? "text" : "password"} id="password" name="password" />
                    <img className="absolute right-4 cursor-pointer my-0 mx-auto" src={showPassword ? "/img/hidden.png" : "/img/view.png"} width="24" onClick={() => setShowPassword(!showPassword)} alt="eye" />
                  </div>
                  {passError ? <span className="text-red-500">Contraseña incorrecta</span> : null}
                  {serverError ? <span className="text-red-500">Error en el servidor</span> : null}
                </div>
                {/* <p onClick={() => setShowPassword(!showPassword)} className="show-password">
              {showPassword ? "Ocultar" : "Mostrar"} contraseña
            </p> */}
              </div>
              <Link className="underline cursor-pointer text-green-dark hover:text-green-light transition-all" to="/recupera">
                ¿Olvidaste tu contraseña?
              </Link>

              <Button className="lg:w-36" onClick={handleSubmit}>
                {loading ? <BallBeat color={"#fff"} loading /> : "INGRESAR"}
              </Button>

              <CheckBox label="Recordar mis datos" onChange={toggleRemember} checked={remember} />
              <Link className="underline cursor-pointer text-green-dark hover:text-green-light transition-all" to="/registration">
                ¿Aún no tienes cuenta?
              </Link>

              <div className="flex items-center px-auto py-8 bg-gray-100 px-4 rounded-xl gap-4 text-gray-500">
                <FontAwesomeIcon className="text-2xl" icon={faShieldAlt} />
                <p className="">Utilizamos el mismo nivel de seguridad que un banco para resguardar tu información</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(NewLogin);
