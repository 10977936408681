import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Calculator from "../calculator/Calculator";

const Page13 = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Conoce nuestra calculadora de préstamos | Vivus Mx</title>
        <meta name="title" content="Conoce nuestra calculadora de préstamos | Vivus Mx" />
        <meta name="description" content="Obtén préstamos personales en línea confiables y sin complicaciones con Vivus. Solicita ahora y disfruta de una solución financiera rápida y segura." />
        <meta name="keywords" content="Haz la simulación de tu préstamo de dinero en línea con la calculadora de préstamos personales de Vivus. ¡Cómoda y fácil de usar!" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1 lg:pb-12">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Haz una simulación con nuestra calculadora de préstamos</h1>
          <img className="w-full" src="/img/landing/woman.jpg" alt="Calculadora de préstamos personales" />
          <h2 className="text-xl font-bold">¿Qué es la calculadora de préstamos de Vivus?</h2>

          <p>La calculadora de préstamos personales de Vivus es una herramienta genial, diseñada para simplificar el proceso de solicitud de tu préstamo y que te brinda la tan necesaria transparencia sobre el monto que tendrás que pagar luego.</p>
          <p>Esta práctica herramienta te ayuda a calcular el calendario de amortización de tu préstamo incluso antes de solicitarlo, lo que te permite planificar mejor tus finanzas. ¡Es como tener un asesor financiero en el bolsillo!</p>

          <h3 className="text-xl font-bold">¿Cómo funciona nuestra calculadora de préstamos personales?</h3>
          <p>Profundicemos en el funcionamiento de esta calculadora de préstamos personales. Solo debes seguir dos pasos clave:</p>

          <ol className="list-decimal pl-5">
            <li>
              <b>Introduce el importe del préstamo que deseas pedir.</b> Puede ser cualquier cantidad dentro de los límites mínimo y máximo establecidos por Vivus (hasta 2.000 MXN en tu primer préstamo y 10.000 MXN en préstamos posteriores
            </li>
            <li>
              <b>Elige el plazo del préstamo:</b>es decir, el periodo durante el cual tienes previsto devolverlo. Puede ser de una semana a 30 días, según tu capacidad de reembolso.
            </li>
          </ol>
          <p>
            Una vez que hayas brindado estos datos, la calculadora de préstamos personales de Vivus calculará al instante el monto final que deberás pagar, incluídos los intereses. La belleza de esta herramienta reside en su precisión y eficacia. Ya no tendrás que adivinar o estimar cuánto debes
            pagar, ¡la calculadora te dará los importes exactos en cuestión de segundos!
          </p>

          <h2 className="text-xl font-bold">Haz tu simulación con la calculadora de préstamos de Vivus</h2>

          <p>Puedes utilizar esta herramienta tantas veces como quieras. Solo ajusta el importe y el plazo del préstamo hasta que encuentres un plan de amortización que se ajuste cómodamente a tu presupuesto.</p>
          <p>Además, como es online, puedes acceder a ella en cualquier momento y desde cualquier lugar. Esto la hace increíblemente cómoda para quienes llevan una vida ajetreada y prefieren gestionar sus finanzas digitalmente.</p>
          <p>En conclusión, la calculadora de préstamos personales de Vivus es una herramienta inestimable para cualquiera que esté pensando en pedir un préstamo. Ofrece transparencia, comodidad y le ayuda a tomar decisiones financieras con conocimiento de causa.</p>
          <p>
            Recuerda que conocer el monto exacto que debes pagar es crucial para no sobrecargar tus finanzas. Así que antes de solicitar un préstamo personal, asegúrate de utilizar la calculadora de préstamos personales de Vivus para hacerte una idea clara de cuánto deberás devolver. ¡Pruébala ya!
          </p>
          <div className="flex justify-center">
            <Calculator />
          </div>
        </div>
      </div>
    </Suspense>
  );
};

Page13.propTypes = {};
Page13.defaultValues = {};

export default Page13;
