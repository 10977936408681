import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { BallBeat } from "react-pure-loaders";
import { getToken, changePassword } from "../../services/api";
import { Link } from "react-router-dom";
import Button from "../common/Button";
import { useLocation } from "../../hooks/location";
import { Helmet } from "react-helmet";

const idProduct = 1;

const PasswordSet = (props) => {
  const [eMail, setEMail] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const { location, ip } = useLocation();

  const sendNewPassword = async () => {
    if (!newPassword) return setPassError(true);
    if (newPassword.length < 6) return setPassError(true);
    if (newPassword.length > 30) return setPassError(true);
    if (newPassword.search(/\d/) === -1) return setPassError(true);
    if (newPassword.search(/[a-zA-Z]/) === -1) return setPassError(true);
    setPassError(false);
    setLoading(true);
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    const data = {
      idProduct,
      eMail,
      userName: eMail,
      coordinates: location,
      newPassword,
      ipAddress: ip,
      userAgent: window.navigator.userAgent,
      password: newPassword,
    };
    changePassword(data, validToken)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSuccess(true);
          return setTimeout(() => props.history.push("/login"), 3000);
        }
        setServerError(true);
        setLoading(false);
      })
      .catch((err) => {
        setServerError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    let validCode = sessionStorage.getItem("code-valid");
    if (!validCode) return props.history.push("/recupera");

    let recoveryMail = sessionStorage.getItem("recoveryEmail");
    if (!recoveryMail) return props.history.push("/recupera");
    setEMail(sessionStorage.getItem("recoveryEmail"));
  }, []);

  return (
    <>
      <Helmet>
        <title>Ingresa tu nueva contraseña| Vivus Mx</title>
        <meta name="title" content="Ingresa tu nueva contraseña | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center items-center flex-1">
        <div className="container flex flex-col gap-4 p-4 ">
          <div className="flex flex-col gap-4 py-4 max-w-xl">
            {/* <DemoButton callback={fillDemo} /> */}
            <div className="w-full flex flex-col gap-2">
              <h1 className="text-3xl lg:text-6xl font-bold text-green-dark"> Ingresa tu nueva contraseña</h1>
            </div>
            <div className={`flex flex-col gap-2`}>
              <label className="font-bold">Nueva contraseña</label>
              <div className="relative w-full flex items-center lg:w-72">
                <input className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm " type={showPassword ? "text" : "password"} placeholder="Nueva contraseña" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                <img className="absolute right-4 cursor-pointer my-0 mx-auto" src={showPassword ? "/img/hidden.png" : "/img/view.png"} width="24" onClick={() => setShowPassword(!showPassword)} alt="eye" />
              </div>

              {serverError ? <span className="text-red-500">Error en el servidor</span> : null}
              <small>La contraseña debe ser de al menos 6 caracteres y debe incluir al menos 1 número</small>
            </div>
            <Button className="lg:w-72" onClick={sendNewPassword}>
              {loading ? <BallBeat color={"#fff"} loading /> : "CAMBIAR CONTRASEÑA"}
            </Button>
            {success ? <span className="text-green-light">Contraseña actualizada correctamente, redirigiendo a inicio de sesión...</span> : null}
            <Link to="/recupera-opcion" className="text-green-light underline hover:text-green-dark transition-all">
              Cambia opción de recuperación
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordSet;
