import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { unregister as unregisterServiceWorker } from "./serviceWorker";
import { Router } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import runOneSignal from "./utils/onesignal";

ReactGA.initialize(process.env.REACT_APP_GTAG);

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.ga("set", "page", location.pathname + location.search);
  ReactGA.ga("set", "location", window.location.href);
  ReactGA.ga("send", "pageview");
});

const instance = createInstance({
  urlBase: "https://dfi.world",
  siteId: process.env.REACT_APP_MATOMO_ID,
  trackerUrl: "https://dfi.world/tracking.php", // optional, default value: `${urlBase}matomo.php`
  srcUrl: "https://dfi.world/tracking.js", // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});


const WithRouter = () => {
  useEffect(() => {
    runOneSignal();
  }, []);

  return (
    <MatomoProvider value={instance}>
      <Router history={history}>
        <App />
      </Router>
    </MatomoProvider>
  );
};

ReactDOM.render(<WithRouter />, document.getElementById("root"));

unregisterServiceWorker();
