import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const AccordionFaq = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="py-4 cursor-pointer border-b border-[#E3E6ED]">
      <div onClick={() => setOpen(!open)} className={`flex w-full justify-between items-center text-lg py-2 pl-4 ${open ? "cursor-pointer" : " "}`}>
        <div className="font-bold text-xl">{question}</div>
        <div className="text-green-light text-lg my-0 mr-4">{open ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
      </div>
      <div className={`h-auto transition-all text-md p-4 text-left ${open ? "" : "hidden"}`}>{answer}</div>
    </div>
  );
};

export default AccordionFaq;
