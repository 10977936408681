import React from "react";

const ReviewCard = ({ review }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-m max-w-md h-[160px] mx-4">
      <div className="flex items-center mb-2">
        <div className="flex text-green-light">
          {"★".repeat(review.rating)}
          {"☆".repeat(5 - review.rating)}
        </div>
      </div>
      <p className="text-gray-700 text-sm mb-4">{review.text}</p>
      <div className="text-sm font-semibold text-green-600">{review.name}</div>
    </div>
  );
};

export default ReviewCard;
