import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { BallBeat } from "react-pure-loaders";
import { getToken, changePassword, sendCodeByNewSMS, changeCustomerMobile, checkCode } from "../../services/api";
import { Link } from "react-router-dom";
import Button from "../common/Button";
import { useLocation } from "../../hooks/location";
import { Helmet } from "react-helmet";

const idProduct = 1;

const PasswordSet = (props) => {
  const [eMail, setEMail] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const { location, ip } = useLocation();

  const sendNewPassword = async () => {
    if (!newPassword) return setPassError(true);
    if (newPassword.length < 6) return setPassError(true);
    if (newPassword.length > 30) return setPassError(true);
    if (newPassword.search(/\d/) === -1) return setPassError(true);
    if (newPassword.search(/[a-zA-Z]/) === -1) return setPassError(true);
    setLoading(true);
    setPassError(false);
    setStep(2);
    setLoading(false);
  };

  const sendCode = async () => {
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    if (!phone) return setPhoneError(true);
    if (phone.length !== 10) return setPhoneError(true);
    setLoading(true);
    sendCodeByNewSMS(
      {
        newEMail: "",
        newMobile: phone,
        userAgent: window.navigator.userAgent,
        remoteIp: ip,
        coordinates: location,
        isNIP: false,
        idCustomer: parseInt(localStorage.recoverIdCustomer),
        idProduct,
      },
      validToken
    )
      .then(() => {
        setStep(3);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePhone = () => {
    setStep(2);
  };

  const updatePassword = async () => {
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;

    const data = {
      idProduct,
      coordinates: location,
      clientIP: ip,
      userAgent: window.navigator.userAgent,
      isNIP: false,
      idCustomer: parseInt(localStorage.recoverIdCustomer),
    };

    checkCode({ ...data, code }, validToken)
      .then((res) => {
        if (res.status === 200) {
          return changeCustomerMobile(
            {
              ...data,
              newEMail: "",
              newMobile: phone,
              remoteIp: ip,
            },
            validToken
          );
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return changePassword(
            {
              ...data,
              eMail,
              userName: eMail,
              newPassword,
              password: newPassword,
              ipAddress: ip,
            },
            validToken
          );
        }
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setServerError(false);
          setSuccess(true);
          return setTimeout(() => props.history.push("/login"), 3000);
        }
      })
      .catch((err) => {
        setServerError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    let validCode = sessionStorage.getItem("code-valid");
    if (!validCode) return props.history.push("/recupera");

    let recoveryMail = sessionStorage.getItem("recoveryEmail");
    if (!recoveryMail) return props.history.push("/recupera");
    setEMail(sessionStorage.getItem("recoveryEmail"));
  }, []);

  return (
    <>
      <Helmet>
        <title> Ingresa tu nueva contraseña | Vivus Mx</title>
        <meta name="title" content="Ingresa tu nueva contraseña | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center items-center flex-1">
        <div className="container flex flex-col gap-4 p-4 ">
          <div className="flex flex-col gap-4 py-4 max-w-xl">
            {/* <DemoButton callback={fillDemo} /> */}
            {step === 1 && (
              <>
                <div className="w-full flex flex-col gap-2">
                  <h1 className="text-3xl lg:text-6xl font-bold text-green-dark"> Ingresa tu nueva contraseña</h1>
                </div>
                <div className={`flex flex-col gap-2`}>
                  <label className="font-bold">Nueva contraseña</label>
                  <div className="relative w-full flex items-center lg:w-72">
                    <input className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm " type={showPassword ? "text" : "password"} placeholder="Nueva contraseña" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    <img className="absolute right-4 cursor-pointer my-0 mx-auto" src={showPassword ? "/img/hidden.png" : "/img/view.png"} width="24" onClick={() => setShowPassword(!showPassword)} alt="eye" />
                  </div>

                  {serverError ? <span className="text-red-500">Error en el servidor</span> : null}
                  <small>La contraseña debe ser de al menos 6 caracteres y debe incluir al menos 1 número</small>
                </div>
                <Button className="lg:w-72" onClick={sendNewPassword}>
                  {loading ? <BallBeat color={"#fff"} loading /> : "CAMBIAR CONTRASEÑA"}
                </Button>
                {success ? <span className="text-green-light">Contraseña actualizada correctamente, redirigiendo a inicio de sesión...</span> : null}
                <Link to="/recupera-opcion" className="text-green-light underline hover:text-green-dark transition-all">
                  Cambia opción de recuperación
                </Link>
              </>
            )}
            {step === 2 && (
              <>
                <div className="w-full flex flex-col gap-2">
                  <h1 className="text-3xl lg:text-6xl font-bold text-green-dark"> Ingresa tu número celular para verificación</h1>
                  <h2 className="text-xl lg:text-2xl text-green-light">Requerimos que confirmes tu número celular para continuar con el cambio de contraseña.</h2>
                </div>
                <div className={`flex flex-col gap-2`}>
                  <label className="font-bold">Número de celular </label>
                  <input className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm lg:w-72" type="text" placeholder="Número de celular" value={phone} maxLength="10" onChange={(e) => setPhone(e.target.value)} />
                  {phoneError && <small className="text-red-500">El número introducido no es válido</small>}
                  {serverError ? <small className="text-red-500">Error en el servidor</small> : null}
                </div>
                <Button className="lg:w-72" onClick={sendCode}>
                  {loading ? <BallBeat color={"#fff"} loading /> : "ACTUALIZAR"}
                </Button>
                {success ? <span className="text-green-light">Contraseña actualizada correctamente, redirigiendo a inicio de sesión...</span> : null}
                <Link to="/recupera-opcion" className="text-green-light underline hover:text-green-dark transition-all">
                  Cambia opción de recuperación
                </Link>
              </>
            )}
            {step === 3 && (
              <>
                <div className="w-full flex flex-col gap-2">
                  <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Verificación telefónica</h1>
                  <h2 className="text-xl lg:text-2xl text-green-light">Hemos enviado un código para verificar tu número de teléfono y puedas recuperar tu contraseña.</h2>
                </div>
                <div className={`flex flex-col gap-2`}>
                  <label className="font-bold">Código de verificación </label>
                  <input className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm lg:w-56" type="text" placeholder="Código de verificación" value={code} maxLength="4" onChange={(e) => setCode(e.target.value)} />
                  {serverError && <small className="text-red-500">Verifica que el código sea correcto, en caso contrario, intenta con otro número de teléfono.</small>}
                  {success ? <small className="text-green-dark">Contraseña actualizada con éxito, en un momento serás redirigido a la pantalla de acceso...</small> : null}
                </div>
                <Button className="lg:w-56" onClick={updatePassword}>
                  {loading ? <BallBeat color={"#fff"} loading /> : "ACTUALIZAR"}
                </Button>
                {success ? <span className="text-green-light">Contraseña actualizada correctamente, redirigiendo a inicio de sesión...</span> : null}
                <Link to="/recupera-opcion" className="text-green-light underline hover:text-green-dark transition-all">
                  Cambia opción de recuperación
                </Link>
                <p className="text-green-light hover:text-green-dark transition-all underline" onClick={changePhone}>
                  Cambiar número
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(PasswordSet);
