import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Page2 = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Préstamos sin buró de crédito en línea| Vivus Mx</title>
        <meta name="title" content="Préstamos sin buró de crédito en línea| Vivus Mx" />
        <meta name="description" content="¡Sin complicaciones! Obtén préstamos sin buró de crédito en línea de forma segura, fácil y rápida. Solicítalo AHORA y obtén el dinero que necesitas." />
        <meta name="keywords" content="prestamos sin buro de credito,prestamos sin aval y sin checar buro de credito,prestamos en linea sin buro,prestamos en linea sin buro de credito" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1 lg:pb-12">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <h1 className="text-4xl font-bold">Préstamos sin buró de crédito</h1>
          <p>
            ¿Necesitas un préstamo, pero te preocupa tu historial crediticio? ¡No te preocupes más! En Vivus, ofrecemos préstamos sin buró de crédito, una solución financiera diseñada para brindarte acceso rápido y sencillo al dinero que necesitas. No estamos para juzgar tu pasado, preferimos
            ayudarte a construir tu futuro. Olvídate de trámites engorrosos y largas esperas, nosotros te ofrecemos préstamos en línea para que puedas resolver tus necesidades de forma confiable y sin complicaciones.
          </p>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
            SOLICITAR PRÉSTAMO
          </a>

          <h2 className="text-2xl font-bold">¿Qué son los préstamos sin buró de crédito?</h2>
          <p>
            Estos son <i className="font-bold">préstamos sin aval y sin checar buro de crédito</i>, lo cual te facilita el acceso al dinero que necesitas de manera rápida y sencilla. Los préstamos sin buró de crédito son una opción financiera accesible para aquellos que tienen un historial
            crediticio negativo, para los que no desean que su historial sea revisado y para los que están comenzando a generar su historial. El buró de crédito es una entidad encargada de recopilar y evaluar la información crediticia de las personas. Se basa en el historial de pagos, deudas y otros
            factores para determinar la solvencia y la capacidad de pago de un individuo. Sin embargo, muchas veces el historial crediticio puede tener irregularidades o no reflejar la situación financiera actual de una persona.
          </p>
          <p>Por ello, en Vivus te ofrecemos estos préstamos sin buró de crédito. Esto implica que a partir de hoy tu historial crediticio no tiene por qué ser un obstáculo para obtener el préstamo que necesitas.</p>

          <h2 className="text-2xl font-bold">Ventajas de solicitar un préstamo sin checar buró de crédito</h2>
          <ul className="list-image-[url(assets/svg/bulletCheck.svg)] pl-5 space-y-2">
            <li>
              <b className="font-bold">Sin revisar historial crediticio:</b> No importa si tienes mal crédito o no tienes historial crediticio, puedes acceder a un préstamo sin buró de crédito.
            </li>
            <li>
              <b className="font-bold">Proceso rápido y sencillo:</b> La solicitud y aprobación del préstamo se realiza en línea, lo que agiliza el proceso y te permite obtener el dinero en poco tiempo.
            </li>
            <li>
              <b className="font-bold">Sin aval ni garantía:</b> No necesitas contar con un aval o garantía para obtener un préstamo sin buró de crédito, lo que te simplifica el proceso y te brinda mayor accesibilidad.
            </li>
            <li>
              <b className="font-bold">Flexibilidad de uso:</b> Puedes utilizar el préstamo sin buró de crédito para cualquier necesidad, ya sea pagar deudas, cubrir gastos imprevistos o realizar proyectos personales.
            </li>
            <li>
              <b className="font-bold">Libertad:</b> Con Vivus tienes dinero disponible cuando lo necesites, sin explicar para qué, sin demostrar nada.
            </li>
          </ul>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
            SOLICITAR PRÉSTAMO
          </a>

          <h2 className="text-2xl font-bold">¿Cómo solicitar mis préstamos en línea sin buró?</h2>
          <p>
            Solicitar préstamos en línea sin buró de crédito en Vivus es un proceso sencillo y conveniente. Sigue estos pasos para obtener el financiamiento que necesitas:
            <ol className="list-decimal pl-5 space-y-2">
              <li>
                <b className="font-bold">Registro y solicitud:</b> Comienza registrándote en nuestra plataforma en línea y completa el formulario con tus datos personales y financieros. Además, selecciona el monto y plazo del préstamo que deseas.
              </li>
              <li>
                <b className="font-bold">Evaluación y aprobación:</b> Una vez que envíes tu solicitud, procederemos con una evaluación precisa para determinar tu elegibilidad. Nuestro proceso de evaluación toma solo unos minutos, y recibirás una respuesta inmediata.
              </li>
              <li>
                <b className="font-bold">Verificación:</b> Cuando tu préstamo sea aprobado, nuestro equipo de verificación se pondrá en contacto contigo para verificar la autenticidad de tu información y confirmar los datos proporcionados.
              </li>
              <li>
                <b className="font-bold">Transferencia de fondos:</b> Una vez que tu préstamo es aprobado, recibirás los fondos directamente en tu cuenta bancaria en un corto periodo de tiempo.
              </li>
              <li>
                <b className="font-bold">Reembolso y condiciones:</b> Ofrecemos flexibilidad en los plazos de reembolso. Estos van desde 7 días hasta 30 días. Es importante que leas y comprendas las condiciones del préstamo, incluyendo los intereses y las fechas de pago, para asegurarte de cumplir
                con tus obligaciones financieras.
              </li>
            </ol>
          </p>

          <h2 className="text-2xl font-bold">¿Dónde puedo pagar mis préstamos personales sin buró de crédito?</h2>
          <p>
            En Vivus, te brindamos múltiples opciones para realizar el pago de tus préstamos en línea sin buró. Queremos que tengas la libertad de elegir la opción que más te convenga. A continuación, te presentamos nuestras formas de pago disponibles:
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <b className="font-bold">Nuestro portal en línea:</b> Puedes ejecutar tus pagos de forma fácil y segura a través de nuestro portal en línea. Simplemente, inicia sesión en tu cuenta y ejecuta el pago en unos cuantos clics.
              </li>
              <li>
                <b className="font-bold">Pay Cash:</b> Te ofrecemos la opción de hacer tus pagos en efectivo a través de la plataforma Pay Cash. Solo necesitas generar un código de barras desde tu cuenta y dirigirte a la tienda más cercana para efectuar el pago.
              </li>
              <li>
                <b className="font-bold">Oxxo Pay:</b> Si prefieres efectuar tus pagos en una tienda Oxxo, puedes utilizar el servicio de Oxxo Pay. Genera tu código de barras en línea y acude a cualquier tienda Oxxo para hacer el pago en efectivo.
              </li>
              <li>
                <b className="font-bold">Transferencia Bancaria:</b> También tienes la opción de ejecutar transferencias bancarias para pagar tus préstamos en línea sin buró. Asegúrate de proporcionar el número de referencia correcto y seguir los pasos indicados para una transacción exitosa.
              </li>
            </ul>
          </p>
          <p>En Vivus nos esforzamos por brindarte comodidad y flexibilidad en tus pagos. Elige la opción que mejor se adapte a tus necesidades y cumple con tus compromisos financieros de manera conveniente.</p>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
            SOLICITAR PRÉSTAMO
          </a>
        </div>
      </div>
    </Suspense>
  );
};

Page2.propTypes = {};
Page2.defaultValues = {};

export default Page2;
