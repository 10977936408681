import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import { HowItWorks } from "../components/works/HowItWorks";
import Register from "../components/register/Register";
import NewLogin from "../components/account/NewLogin";
import NewProfile from "../components/account/profile/NewProfile";
import Cargo from "../components/account/Cargo";
import Identificacion from "../components/account/Identificacion";
import NewDashboard from "../components/account/NewDashboard";
import NewForgotPassword from "../components/account/NewForgotPassword";
import PasswordRecoveryEmail from "../components/account/PasswordRecoveryEmail";
import PasswordRecoveryEmailConfirmation from "../components/account/PasswordRecoveryEmailConfirmation";
import PasswordRecoveryMobileConfirmation from "../components/account/PasswordRecoveryMobileConfirmation";
import PasswordSet from "../components/account/PasswordSet";
import { NotFound } from "../components/account/NotFound";
import { IdentityValidated } from "../components/register/IdentityValidated";
import { PreApproved } from "../components/register/PreApproved";
import { ApplicationReview } from "../components/register/ApplicationReview";
import { NotApproved } from "../components/register/NotApproved";
import AvisoDePrivacidad from "../components/common/footer-links/AvisoDePrivacidad";
import TerminosCondiciones from "../components/common/footer-links/TerminosCondiciones";
import { AboutUs } from "../components/aboutUs/AboutUs";
import { Error } from "../components/common/Error";
import { SecondLoan } from "../components/account/SecondLoan";
import { Manteinance } from "../components/common/Manteinance";
import { BallClipRotate } from "react-pure-loaders";
import PasswordSetWithConfirm from "../components/account/PasswordSetWithConfirm";
import { ErrorTimeout } from "../components/common/ErrorTimeout";
import SSO from "../components/register/steps/SSO";
import { RegulatoryInfo } from "../components/register/RegulatoryInfo";
import { LoanReview } from "../components/register/LoanReview";
import Page1 from "../components/content/Page1";
import Page2 from "../components/content/Page2";
import Page3 from "../components/content/Page3";
import Page4 from "../components/content/Page4";
import Page5 from "../components/content/Page5";
import Page6 from "../components/content/Page6";
import Page7 from "../components/content/Page7";
import Page8 from "../components/content/Page8";
import Page9 from "../components/content/Page9";
import Page10 from "../components/content/Page10";
import Page11 from "../components/content/Page11";
import Page12 from "../components/content/Page12";
import Page13 from "../components/content/Page13";
import Page14 from "../components/content/Page14";
import { PromoTermsAndConditions } from "../components/common/footer-links/PromoTermsAndConditions";
import ShadowPage1 from "../components/content/shadow/ShadowPage1";
import ShadowPage2 from "../components/content/shadow/ShadowPage2";
import ShadowPage3 from "../components/content/shadow/ShadowPage3";
import ShadowPage4 from "../components/content/shadow/ShadowPage4";
import ShadowPage6 from "../components/content/shadow/ShadowPage6";
import ShadowPage5 from "../components/content/shadow/ShadowPage5";
import ShadowPage7 from "../components/content/shadow/ShadowPage7";
import Home from "../components/landing/Home";
import PrestamosMorelia from "../components/seo/PrestamosMorelia";
import PrestamosCDMX from "../components/seo/PrestamosCDMX";
import PrestamosInmediatos from "../components/seo/PrestamosInmediatos";
import SolicitudCredito from "../components/seo/SolicitudCredito";
import PrestamosAguascalientes from "../components/seo/PrestamosAguascalientes";
import PrestamosTijuana from "../components/seo/PrestamosTijuana";
import CreditosEnLinea from "../components/seo/CreditosEnLinea";
import DineroRapido from "../components/seo/DineroRapido";
import HistorialCrediticio from "../components/seo/HistorialCrediticio";
import PagarMiPrestamo from "../components/seo/PagarMiPrestamo";
import PrestamosPuebla from "../components/seo/PrestamosPuebla";
import PrestamosQueretaro from "../components/seo/PrestamosQueretaro";
import TasaDeInteres from "../components/seo/TasaDeInteres";

const Routes = () => {
  // For manteinance, change inManteinance default value to true
  const [inManteinance, setInManteinance] = useState(false);
  const [loading, setLoading] = useState(true);

  // Uncomment for manteinance page and change date range
  useEffect(() => {
    // let startDate   = momentEs("16-08-2020 01:00", "DD/MM/YYYY HH:mm")
    // let endDate     = momentEs("20-08-2020 08:00", "DD/MM/YYYY HH:mm")
    // if(momentEs().isBetween(startDate, endDate)) setInManteinance(true)
    // else setInManteinance(false)
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <div className="router-loading">
          <BallClipRotate loading color="#A3CD3A" />
        </div>
      ) : inManteinance ? (
        <Switch>
          {/* RUTAS EN mantenimiento */}
          {/* <Route exact path='/' component={Home}/> */}
          <Route exact path="/en-mantenimiento" component={Manteinance} />
          <Route path="/*" render={() => <Redirect to="/en-mantenimiento" />} />

          {/** automated login page (token required) */}
        </Switch>
      ) : (
        <>
          <Switch>
            {/* <Route exact path='/en-mantenimiento' component={Manteinance} />  */}
            {/* <Route exact path='/' render={props => <Redirect to='/en-mantenimiento'/>} />  */}
            {/* Home */}
            <Route exact path="/" component={Home} />
            {/* Landing sections */}
            <Route path="/como-funciona" component={HowItWorks} />
            <Route
              path="/app"
              render={(props) => {
                window.location.href = "https://play.google.com/store/apps/details?id=mx.vivus&utm_source=vivus-website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
              }}
            />
            <Route path="/sobre-nosotros" component={AboutUs} />
            {/* Login / Recover password */}
            <Route path="/login" component={NewLogin} />
            <Route path="/sso" render={(props) => <SSO {...props} />} />
            <Route exact path="/registration" render={(props) => <Register {...props} url={0} />} />
            <Route path="/registration/personal-details" render={(props) => <Register {...props} url={1} />} />
            <Route path="/registration/employment-details" render={(props) => <Register {...props} url={2} />} />
            <Route path="/registration/nip-bureau" render={(props) => <Register {...props} url={3} />} />
            <Route path="/registration/identity" render={(props) => <Register {...props} url={4} />} />
            <Route path="/registration-complete" render={(props) => <Register {...props} url={5} />} />
            <Route path="/registration/questionary" render={(props) => <Register {...props} url={6} questionnaire />} />
            <Route path="/application-complete" component={PreApproved} />
            <Route path="/application-review" component={ApplicationReview} />
            <Route path="/identity-validated" component={IdentityValidated} />
            <Route path="/loan-review" component={LoanReview} />
            <Route path="/rejected" component={NotApproved} />
            <Route path="/recupera" component={PasswordRecoveryEmail} />
            <Route path="/recupera-opcion" component={NewForgotPassword} />
            <Route path="/confirmacion-email" component={PasswordRecoveryEmailConfirmation} />
            <Route path="/confirmacion-sms" component={PasswordRecoveryMobileConfirmation} />
            <Route path="/nuevo-password" component={PasswordSet} />
            <Route path="/nuevo-password-confirmar" component={PasswordSetWithConfirm} />
            <Route path="/denied" component={NotApproved} />
            {/* Application */}
            {/* <Route path='/dashboard/initial' component={FirstTimeDash}/> */}
            <Route exact path="/dashboard" component={SecondLoan} />
            <Route path="/perfil" component={NewProfile} />
            {/* <Route path='/dashboard/buro' component={Buro}/> */}
            {/* <Route path="/dashboard/confirm" component={CargoAuto} /> */}
            <Route path="/pre-approved" component={Cargo} />
            <Route path="/dashboard/id" component={Identificacion} />
            <Route path="/dashboard/" component={NewDashboard} />
            {/* <Route path='/borrow-more' component={NewDashboard}/> */}
            {/* LINKS FOOTER */}

            <Route path="/contenido/aviso-de-privacidad" component={AvisoDePrivacidad} />
            <Route path="/informacion-regulatoria" component={RegulatoryInfo} />
            <Route path="/contenido/terminos-y-condiciones" component={TerminosCondiciones} />
            <Route path="/contenido/terminos-y-condiciones-plazos" component={PromoTermsAndConditions} />
            {/* REPEATERS */}
            <Route path="/repeated/application/complete" render={(props) => <Register {...props} url={5} repeater />} />
            <Route path="/repeated/application/pre-approved" render={(props) => <PreApproved {...props} repeater />} />
            <Route path="/repeated/application/rejected" render={(props) => <NotApproved {...props} repeater />} />
            {/* ADDITIONAL AMOUNT */}
            <Route path="/additional/application/pre-approved" render={(props) => <Cargo {...props} additional />} />
            <Route path="/additional/application/complete" render={(props) => <PreApproved {...props} additional />} />
            {/* <Route exact path="/historial-crediticio" component={CreditReportPage} />
            <Route exact path="/registro-historial-crediticio" component={CreditReportFormPage} /> */}
            <Route path="/error" component={Error} />
            <Route path="/error-timeout" component={ErrorTimeout} />

            {/* Content */}
            <Route path="/prestamos-confiables" component={Page1} />
            <Route path="/prestamos-sin-buro-de-credito" component={Page2} />
            <Route path="/prestamos-seguros-y-confiables" component={Page3} />
            <Route path="/prestamos-express" component={Page4} />
            <Route path="/prestamos-en-linea-rapidos" component={Page5} />
            <Route path="/prestamos-en-linea-urgentes" component={Page6} />
            <Route path="/app-de-prestamos-confiables" component={Page7} />
            <Route path="/minicreditos" component={Page8} />
            <Route path="/microcreditos" component={Page9} />
            <Route path="/prestamos-personales" component={Page10} />
            <Route path="/app-de-prestamos-en-linea" component={Page11} />
            <Route path="/como-solicitar-un-prestamo" component={Page12} />
            <Route path="/calculadora-de-prestamos" component={Page13} />
            <Route path="/prestamos-seguros" component={Page14} />

            <Route path="/prestamos-en-linea-sin-buro-de-credito" component={ShadowPage1} />
            <Route path="/prestamos-en-linea-confiables" component={ShadowPage2} />
            <Route path="/prestamos-express-en-linea" component={ShadowPage3} />
            <Route path="/prestamos-rapidos-y-seguros" component={ShadowPage4} />
            <Route path="/prestamos-urgentes" component={ShadowPage5} />
            <Route path="/microcreditos-inmediatos" component={ShadowPage6} />
            <Route path="/prestamos-confiables-en-guadalajara-y-expres" component={ShadowPage7} />

            {/* SEO Pages */}
            <Route path="/prestamos-en-morelia" component={PrestamosMorelia} />
            <Route path="/prestamos-en-cdmx" component={PrestamosCDMX} />
            <Route path="/prestamo-inmediato" component={PrestamosInmediatos} />
            <Route path="/solicitud-de-credito" component={SolicitudCredito} />
            <Route path="/prestamos-en-aguascalientes" component={PrestamosAguascalientes} />
            <Route path="/prestamos-en-tijuana" component={PrestamosTijuana} />
            <Route path="/creditos-en-linea" component={CreditosEnLinea} />
            <Route path="/dinero-rapido" component={DineroRapido} />
            <Route path="/historial-crediticio" component={HistorialCrediticio} />
            <Route path="/pagar-mi-prestamo" component={PagarMiPrestamo} />
            <Route path="/prestamos-en-puebla" component={PrestamosPuebla} />
            <Route path="/prestamos-en-queretaro" component={PrestamosQueretaro} />
            <Route path="/tasa-de-interes" component={TasaDeInteres} />

            <Route exact path="/contenido/prestamo-inmediato">
              <Redirect to="/" />
            </Route>
            <Route exact path="/prestamos-seguros-y-confiable">
              <Redirect to="/" />
            </Route>
            <Route exact path="/blogdiferencias-entre-caja-de-ahorro-fondo-de-ahorro">
              <Redirect to="/" />
            </Route>
            <Route exact path="/blog/acciones-diarias-que-te-ayudaran-a-mantener-tus-finanzas-personales-estables">
              <Redirect to="/" />
            </Route>
            <Route exact path="/blog/Como-funciona-el-presupuesto-50-30-20">
              <Redirect to="/" />
            </Route>
            <Route exact path="/blog/infonavit-o-fovissste">
              <Redirect to="/" />
            </Route>
            <Route exact path="/blog/Por-que-estar-en-buro-de-credito-es-positivo">
              <Redirect to="/" />
            </Route>
            <Route exact path="/blog/Prestamo-personal-requisitos-y-tramites">
              <Redirect to="/" />
            </Route>

            {/* 404 */}
            <Route
              render={(something) => {
                console.log(something);
                // if (staticContext) {
                //   staticContext.statusCode = 404;
                // }
                return <NotFound />;
              }}
            />
          </Switch>
        </>
      )}
    </>
  );
};

export default Routes;
