import React, { Suspense } from "react";
import { Helmet } from "react-helmet";

const ShadowPage1 = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Préstamos sin Buró de Crédito ¡Aplica HOY! | Vivus Mx</title>
        <meta name="title" content="Préstamos sin Buró de Crédito ¡Aplica HOY! | Vivus Mx" />
        <meta name="description" content="¿Requieres efectivo de manera urgente? Obtén préstamos sin Buró de Crédito y sin aval con Vivus. ¡Haz clic y solicita hoy mismo!" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1 lg:pb-12">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Préstamos en línea sin Buró de Crédito: rápidos, fáciles y confiables </h1>

          <h2 className="text-xl font-bold">¡Obtén dinero sin preocuparte por tu historial crediticio!</h2>

          <h3 className="text-lg font-bold">¿Necesitas Dinero Rápido? ¡Estamos aquí para ayudarte!</h3>

          <p>
            Si buscas <b className="font-bold">préstamos sin buró de crédito</b>, estás en el lugar correcto. En Vivus, comprendemos que la vida puede presentar desafíos financieros inesperados que requieren respuestas rápidas y efectivas. Nuestros{" "}
            <b className="font-bold">préstamos en línea sin aval y sin checar buró de crédito</b> están diseñados para brindarte una solución confiable y accesible, independientemente de tu historial crediticio.
          </p>

          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href={"https://www.vivus.com.mx/registration"}>
            Solicita tu préstamo AHORA
          </a>

          <h2 className="text-xl font-bold">Flexibilidad y Comodidad en cada paso</h2>

          <p>
            Entendemos que las circunstancias financieras de cada persona son únicas. Por eso, nos enorgullece ofrecer{" "}
            <a className="font-bold text-green-dark" href={"https://www.vivus.com.mx/prestamos-seguros-y-confiables"}>
              préstamos en línea confiables
            </a>{" "}
            que se adaptan a tus necesidades específicas. Nuestra plataforma de solicitud es intuitiva y sencilla, permitiéndote solicitar tu préstamo con facilidad desde la comodidad de tu hogar. Eliminamos las largas filas y los trámites engorrosos de una vez por todas. Ahora puedes acceder al
            dinero que necesitas de la forma más conveniente.
          </p>

          <h2 className="text-xl font-bold">Transparencia y confianza en cada transacción</h2>
          <p>
            La confianza es esencial en todas nuestras interacciones. En Vivus, te ofrecemos <b className="font-bold">condiciones de préstamo claras</b> y términos comprensibles para que puedas tomar decisiones informadas. Valoramos la transparencia y nos esforzamos por establecer una relación
            sólida y honesta contigo. Nuestro equipo de profesionales está aquí para brindarte la asistencia que necesitas en cada paso del proceso.
          </p>

          <h2 className="text-xl font-bold">Tu Historial Crediticio no Define tu Futuro Financiero </h2>
          <p>
            ¿Te preocupa tu historial crediticio? Con nuestros{" "}
            <a className="font-bold text-green-dark" href="https://www.vivus.com.mx/prestamos-personales">
              préstamos personales en línea
            </a>
            , puedes dejar atrás esa preocupación. En Vivus, creemos en tu potencial y en <b className="font-bold">tu capacidad para superar cualquier obstáculo</b>. No te dejamos atrapado en los rigurosos requisitos de las instituciones tradicionales. En su lugar, nos enfocamos en tus necesidades
            actuales y en brindarte la ayuda que mereces.
          </p>

          <h2 className="text-xl font-bold">Comienza tu Solicitud Hoy Mismo</h2>
          <p>
            La solución a tus necesidades financieras está a solo un clic de distancia. Ya sea que estés enfrentando gastos inesperados, invirtiendo en tus sueños o lidiando con emergencias, nuestros <b className="font-bold">préstamos en línea sin buro de crédito</b> están diseñados para apoyarte.
            No permitas que tu historial crediticio te detenga.
          </p>

          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="https://www.vivus.com.mx/registration">
            Solicita tu préstamo AHORA
          </a>

          <h3 className="text-lg font-bold">¿Cómo solicitar préstamos sin buró de crédito?</h3>
          <ol className="space-y-4 list-decimal">
            <li>
              <b className="font-bold">Llena el Formulario</b>
              <br />
              Ingresa a nuestro sitio web o app y completa el formulario de solicitud. Proporciona tus datos personales y la cantidad de dinero que necesitas.
            </li>
            <li>
              <b className="font-bold">Selecciona las Condiciones </b>
              <br />
              Elige el plazo y las condiciones que mejor se adapten a tus necesidades. Nuestro sistema te mostrará claramente los detalles, para que tomes una decisión informada.
            </li>
            <li>
              <b className="font-bold">Verifica tu Identidad</b>
              <br />
              Sube el documento solicitado para verificar tu identidad.
            </li>
            <li>
              <b className="font-bold">Espera la aprobación </b>
              <br />
              Nuestro equipo evaluará tu solicitud y te notificará sobre la aprobación en poco tiempo. Si todo está en orden, recibirás la confirmación en tu correo electrónico.{" "}
            </li>
            <li>
              <b className="font-bold">Recibe tu dinero </b>
              <br />
              Una vez aprobado, el dinero será transferido a tu cuenta bancaria. Dependiendo de la entidad bancaria, el tiempo de recepción puede variar, pero generalmente es muy rápido.
            </li>
          </ol>

          <h2 className="text-xl font-bold">Tu socio confiable en préstamos en línea sin Buró de Crédito </h2>
          <p>
            Nuestros <b className="font-bold">préstamos en línea sin buro de crédito</b> están respaldados por un equipo experimentado y comprometido que se preocupa por ofrecerte el mejor servicio. No importa tu pasado; te brindamos otra oportunidad y un camino hacia la estabilidad financiera. Tu
            éxito es nuestra prioridad, por ello estamos aquí para apoyarte.
          </p>
          <h2 className="text-xl font-bold">Solución Rápida, Respuesta Efectiva</h2>
          <p>
            Nuestro proceso de solicitud es rápido y eficiente. Olvídate de las esperas interminables. En Vivus, agilizamos el proceso para que puedas obtener una respuesta inmediata. No solo te ofrecemos préstamos en línea sin buro de crédito, sino que también te brindamos la tranquilidad de saber
            que estás tomando el control de tus finanzas de manera efectiva.
          </p>
          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="https://www.vivus.com.mx/registration">
            Solicita tu préstamo AHORA
          </a>
        </div>
      </div>
    </Suspense>
  );
};

ShadowPage1.propTypes = {};
ShadowPage1.defaultValues = {};

export default ShadowPage1;
