import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CoverSEO from "./shared/CoverSEO";
import ReviewSlider from "./shared/ReviewSlider";
import FAQ from "./shared/FAQ";

const questions = ["¿Qué necesito para solicitar un préstamo en Vivus?", "¿Cuánto tiempo tarda en procesar mi solicitud?", "¿Puedo obtener un préstamo si tengo mal historial crediticio?"];

const answers = ["Solo necesitas una identificación oficial y una cuenta bancaria.", "La evaluación y aprobación se realizan en minutos, y el dinero se deposita en tu cuenta en menos de 24 horas.", "Sí, en Vivus checamos pero no es un factor decisivo. Evaluamos tu capacidad actual de pago."];

const PrestamosMorelia = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Préstamos en Morelia: ¿Qué Alternativas Existen? | Vivus Mx</title>
        <meta name="title" content="Préstamos en Morelia: ¿Qué Alternativas Existen? | Vivus Mx" />
        <meta name="description" content="Obtén préstamos personales rápidos y confiables en Morelia con Vivus. Solicita en línea sin checar buró de crédito y recibe tu dinero en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="relative w-full flex justify-center flex-1 lg:pb-12 flex-col items-center text-[#65696E]">
        <CoverSEO
          title="Préstamos en Morelia: ¿Qué Alternativas Existen?"
          description="¿Necesitas dinero rápido y seguro? En Vivus, te ofrecemos préstamos en línea para cubrir tus necesidades financieras de manera fácil y confiable. Descubre cómo funcionan nuestros préstamos rápidos y cómo solicitarlos de forma sencilla."
        />
        <div className="container flex flex-col gap-4 p-4">
          <img src={`/img/seo/seo_morelia.jpg`} alt="préstamos en morelia" className="w-full object-cover hidden lg:block" />
          <img src={`/img/seo/seo_morelia_sm.jpg`} alt="préstamos en morelia" className="w-full object-cover lg:hidden" />
          <p>
            Las personas en Morelia son reconocidas por su arduo trabajo y su espíritu emprendedor. Si estás buscando <b>préstamos en Morelia</b>, seguramente tienes una urgencia económica. No te preocupes, Vivus se especializa en{" "}
            <a href="https://www.vivus.com.mx/" className="underline text-green-dark">
              préstamos en línea
            </a>
            . Puedes aplicar en minutos sin papeleo tedioso y recibir tu dinero rápidamente.
          </p>
          <h2 className="text-xl font-bold">Ventajas de los Préstamos en Morelia con Vivus</h2>

          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Rápidos y eficientes</b>: Obtén tu dinero en minutos sin tener que esperar largos procesos.
            </li>
            <li>
              <b className="font-bold">Transparencia Total</b>: Conoce todas las condiciones desde el principio, sin sorpresas ocultas.
            </li>
            <li>
              <b className="font-bold">Flexibilidad</b>: Ofrecemos opciones de financiamiento que se ajustan a tus necesidades.
            </li>
            <li>
              <b className="font-bold">Sin Buró de Crédito</b>: No importa tu historial crediticio, evaluamos tu capacidad actual de pago.
            </li>
          </ul>

          <h2 className="text-xl font-bold">¿Cómo solicitar mi primer préstamo?</h2>

          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Solicitud</b>: Crea tu cuenta en VIVUS.
            </li>
            <li>
              <b className="font-bold">Registro</b>: Ingresa tus datos personales.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Tu solicitud será evaluada rápidamente, y en cuestión de minutos, recibirás una respuesta.
            </li>
            <li>
              <b className="font-bold">Depósito del dinero</b>: Si tu solicitud es aprobada, el dinero se deposita directamente en tu cuenta bancaria, listo para ser usado según tus necesidades.
            </li>
          </ol>

          <p>*Sujeto a verificación de datos y aprobación de crédito.</p>

          <h2 className="text-xl font-bold">Financieras Confiables en Morelia</h2>

          <p>
            Las personas confían en Vivus por su transparencia total y rapidez en el proceso de aprobación y desembolso de préstamos. Además, la seguridad de los datos personales y la flexibilidad en los plazos y montos de los préstamos son factores clave. Con más de 8 años en el mercado, la empresa
            ha demostrado ser una opción confiable y regulada, ofreciendo un servicio eficiente y sin requisitos estrictos de historial crediticio. Si buscas financieras confiables en Morelia, Vivus es tu mejor opción para préstamos en línea seguros y rápidos.
          </p>

          <h2 className="text-xl font-bold">Beneficios de Elegir Vivus para tus Préstamos en Morelia</h2>

          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Sin papeleo</b>: Todo el proceso del préstamo personal es digital, sin necesidad de trámites complicados.
            </li>
            <li>
              <b className="font-bold">Confidencialidad</b>: Tus datos están seguros con nosotros.
            </li>
            <li>
              <b className="font-bold">Flexibilidad de pago</b>: Ofrecemos opciones de pago que se adaptan a tus posibilidades y extensiones en caso de que no puedas cumplir con la fecha límite.
            </li>
          </ul>
        </div>
        <div className="w-screen">
          <ReviewSlider
            reviews={[
              {
                rating: 5,
                text: "Vivus es increíblemente rápido y confiable. Necesitaba un préstamo urgente y en minutos tenía el dinero en mi cuenta.",
                name: "Carlos M.",
              },
              {
                rating: 5,
                text: "La mejor opción en Morelia para préstamos personales. El proceso fue sencillo y sin complicaciones.",
                name: "María P.",
              },
            ]}
          />
        </div>

        <div className="container flex flex-col gap-4 p-4">
          <FAQ questions={questions} answers={answers} />
          <p>
            Si vives en Morelia y necesitas un préstamo rápido y seguro, Vivus es tu mejor opción. Solicita tu préstamo hoy y descubre por qué somos líderes en <b className="font-bold">préstamos en línea </b>para Morelia.
          </p>
          {/* ------ */}
        </div>
      </div>
    </Suspense>
  );
};

PrestamosMorelia.propTypes = {};
PrestamosMorelia.defaultValues = {};

export default PrestamosMorelia;
