import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CoverSEO from "./shared/CoverSEO";
import ReviewSlider from "./shared/ReviewSlider";
import FAQ from "./shared/FAQ";

const questions = ["¿Qué necesito para solicitar un préstamo en Vivus?", "¿Cuánto tiempo tarda en procesar mi solicitud?", "¿Puedo obtener un préstamo si tengo mal historial crediticio?"];

const answers = ["Solo necesitas una identificación oficial y una cuenta bancaria.", "La evaluación y aprobación se realizan en minutos, y el dinero se deposita en tu cuenta en menos de 24 horas.", "Sí, en Vivus checamos pero no es un factor decisivo. Evaluamos tu capacidad actual de pago."];

const PrestamosQueretaro = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Préstamos en Querétaro cuando lo necesites | Vivus Mx</title>
        <meta name="title" content="Préstamos en Querétaro cuando lo necesites | Vivus Mx" />
        <meta name="description" content="Obtén préstamos rápidos y confiables en Querétaro con Vivus. Solicita en línea sin papeleo y recibe tu dinero en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="relative w-full flex justify-center flex-1 lg:pb-12 flex-col items-center text-[#65696E]">
        <CoverSEO title="Préstamos en Querétaro: Todo lo que Necesitas Saber" description="Obtén préstamos rápidos y confiables en Querétaro con Vivus. Solicita en línea sin papeleo y recibe tu dinero en minutos." />
        <div className="container flex flex-col gap-4 p-4">
          <img src={`/img/seo/seo_queretaro.jpg`} alt="préstamos rápidos querétaro" className="w-full object-cover hidden lg:block" />
          <img src={`/img/seo/seo_queretaro_sm.jpg`} alt="préstamos rápidos querétaro" className="w-full object-cover lg:hidden" />
          <h2 className="text-xl font-bold">Préstamos en Querétaro: Todo lo que Necesitas Saber</h2>
          <p className="font-bold">
            Si te encuentras en Querétaro y necesitas un préstamo, Vivus es tu mejor opción. Somos una financiera confiable que ofrece{" "}
            <a href="https://www.vivus.com.mx/" className="underline text-green-dark">
              préstamos en línea
            </a>{" "}
            rápidos y sin complicaciones. No importa si necesitas dinero para una emergencia o para un proyecto personal, en Vivus te ofrecemos las mejores condiciones.
          </p>

          <h2 className="text-xl font-bold">Ventajas de los Préstamos en Querétaro con Vivus</h2>

          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Rapidos y Eficientes</b>: Obtén tu dinero en minutos sin largos procesos.{" "}
            </li>
            <li>
              <b className="font-bold">Transparencia Total</b>: Conoce todas las condiciones desde el principio, sin sorpresas ocultas, puedes hacerlo en la calculadora de préstamos.
            </li>
            <li>
              <b className="font-bold">Flexibilidad</b>: Ofrecemos opciones de financiamiento que se ajustan a tus necesidades.{" "}
            </li>
            <li>
              <b className="font-bold">Sin Buró de Crédito</b>: No importa tu historial crediticio, evaluamos tu capacidad actual de pago.
            </li>
          </ul>

          <h2 className="text-xl font-bold">Financieras Confiables en Querétaro</h2>
          <p>Vivus se destaca entre las financieras confiables en Querétaro por su compromiso con la transparencia y la seguridad. Con más de 8 años en el mercado, ofrecemos un servicio eficiente y regulado, sin requisitos estrictos de historial crediticio.</p>
          <h2 className="text-xl font-bold">¿Cómo Solicitar un préstamo en Querétaro?</h2>
          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Solicitud</b>: Crea tu cuenta en{" "}
              <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
                VIVUS
              </a>
              .
            </li>
            <li>
              <b className="font-bold">Registro</b>: Ingresa tus datos personales.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Tu solicitud será evaluada rápidamente, y en cuestión de minutos, recibirás una respuesta.
            </li>
            <li>
              <b className="font-bold">Depósito del dinero</b>: Si tu solicitud es aprobada, el dinero se deposita directamente en tu cuenta bancaria, listo para ser usado según tus necesidades.
            </li>
          </ol>
          <h2 className="text-xl font-bold">Beneficios de Elegir Vivus para tus Préstamos en Querétaro</h2>
          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Sin papeleo</b>: Todo el proceso de tu{" "}
              <a href="https://www.vivus.com.mx/prestamos-personales" className="underline text-green-dark">
                préstamo personal
              </a>{" "}
              es digital, sin necesidad de trámites complicados.
            </li>
            <li>
              <b className="font-bold">Confidencialidad</b>: Tus datos están seguros con nosotros.
            </li>
            <li>
              <b className="font-bold">Flexibilidad de pago</b>: Ofrecemos opciones de pago que se adaptan a tus posibilidades y extensiones en caso de que no puedas cumplir con la fecha límite.
            </li>
          </ul>
        </div>
        <div className="w-screen">
          <ReviewSlider
            title="Reseñas de Clientes en Querétaro"
            reviews={[
              {
                rating: 5,
                text: "Vivus es increíblemente rápido y confiable. Necesitaba un préstamo urgente y en minutos tenía el dinero en mi cuenta.",
                name: "Carlos M.",
              },
              {
                rating: 5,
                text: "La mejor opción en Querétaro para préstamos personales. El proceso fue sencillo y sin complicaciones.",
                name: "María P.",
              },
            ]}
          />
        </div>
        <div className="container flex flex-col gap-4 p-4 mb-8">
          <FAQ questions={questions} answers={answers} title="Preguntas Frecuentes sobre Préstamos en Querétaro" />
          <h2 className="text-xl font-bold">Tip Financiero: Reduce y Controla tus Deudas</h2>
          <p>
            Es importante mantener tus deudas bajo control para asegurar una salud financiera sólida. Un buen consejo financiero es priorizar el pago de deudas con altos intereses primero. Considera consolidar tus deudas en un solo préstamo con una tasa de interés más baja para facilitar el manejo
            de los pagos. Además, establece un presupuesto mensual que incluya pagos regulares hacia tus deudas, evitando nuevos endeudamientos innecesarios. Controlar tus deudas te permitirá ahorrar dinero en intereses y mejorar tu score crediticio.
          </p>
        </div>
      </div>
    </Suspense>
  );
};

PrestamosQueretaro.propTypes = {};
PrestamosQueretaro.defaultValues = {};

export default PrestamosQueretaro;
