import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Page7 = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>App de préstamos confiables| Vivus Mx</title>
        <meta name="title" content="App de préstamos confiables| Vivus Mx" />
        <meta name="description" content="Descarga la app de préstamos confiables de Vivus y solicita dinero en línea sin buro de crédito. ¡Rápido, seguro y sin complicaciones!" />
        <meta name="keywords" content="app préstamos en línea,app de préstamos confiables,mejores apps de prestamos,app de préstamos sin buro,app confiables para pedir prestamos" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1 lg:pb-12">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Descarga la app de préstamos confiables de Vivus y obtén dinero rápido</h1>
          <p>¿Necesitas comenzar ese proyecto o hacer frente a un gasto inesperado? ¡Has llegado al lugar indicado! Nuestra aplicación te ofrece la solución perfecta para obtener préstamos de manera rápida y segura.</p>
          <p>¡No esperes más y únete a nuestra comunidad de usuarios satisfechos!</p>
          <a
            className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl"
            href="https://play.google.com/store/apps/details?id=mx.vivus&utm_source=vivus-website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
          >
            DESCARGAR APP VIVUS
          </a>

          <h2 className="text-xl font-bold">¿Cómo saber si una aplicación de préstamos es confiable?</h2>
          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Reputación:</b> Investiga la reputación de la empresa detrás de la aplicación.
            </li>
            <li>
              <b className="font-bold">Opiniones de clientes:</b> Lee las opiniones y comentarios de otros usuarios sobre la aplicación desde Google Play.
            </li>
            <li>
              <b className="font-bold">Seguridad de datos:</b> Asegúrate de que la aplicación cuente con altos estándares de seguridad para proteger tu información personal y financiera.
            </li>
            <li>
              <b className="font-bold">Transparencia:</b> Una aplicación confiable debe ser transparente en cuanto a sus condiciones y requisitos.
            </li>
            <li>
              <b className="font-bold">Atención al cliente:</b> Una aplicación confiable debe contar con un equipo de atención al cliente disponible para resolver tus dudas y brindarte asistencia.
            </li>
          </ul>
          <p>Considerar estos aspectos te ayudará a evaluar la confiabilidad de una aplicación de préstamos y tomar la mejor decisión para tus necesidades financieras.</p>

          <h2 className="text-xl font-bold">¿Por qué la app de Vivus se destaca entre las mejores apps de préstamos?</h2>
          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Experiencia y trayectoria:</b> Contamos con una amplia experiencia en el sector financiero y una trayectoria sólida, respaldada por miles de clientes satisfechos.
            </li>
            <li>
              <b className="font-bold">Seguridad:</b> Implementamos rigurosas medidas de seguridad para proteger tus datos personales y financieros. Puedes confiar en que tu información estará resguardada de forma segura.
            </li>
            <li>
              <b className="font-bold">Proceso sencillo:</b> Nuestra app está diseñada para ser fácil de usar y comprender. Puedes completar el proceso de solicitud de préstamo de manera rápida y sin complicaciones.
            </li>
            <li>
              <b className="font-bold">Rapidez en la aprobación:</b> En Vivus, comprendemos la importancia de la rapidez. Esta es una app de préstamos sin buró de crédito. Evaluamos tu solicitud de préstamo de forma ágil y, si es aprobada, podrás recibir el dinero en tu cuenta en poco tiempo.
            </li>
            <li>
              <b className="font-bold">Transparencia y confiabilidad:</b> Te proporcionamos información clara y detallada sobre las condiciones del préstamo, sin sorpresas ocultas.
            </li>
            <li>
              <b className="font-bold">Atención al cliente:</b> Nuestro equipo de atención al cliente está disponible para responder tus preguntas y brindarte asistencia personalizada en cada etapa del proceso.
            </li>
          </ul>
          <p>Descubre por ti mismo por qué nuestra app es la opción más confiable para obtener préstamos en línea de manera rápida y segura.</p>
          <a
            className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl"
            href="https://play.google.com/store/apps/details?id=mx.vivus&utm_source=vivus-website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
          >
            DESCARGAR APP VIVUS
          </a>

          <h2 className="text-xl font-bold">Ventajas de usar la aplicación de préstamos en línea de Vivus</h2>
          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">RAPIDEZ:</b> Obtén una respuesta inmediata a tu solicitud de préstamo y recibe el dinero en tu cuenta en poco tiempo.
            </li>
            <li>
              <b className="font-bold">SIN PAPELEOS:</b> Olvídate de los trámites engorrosos y el papeleo excesivo. Nuestra app te permite realizar todo el proceso de solicitud de manera digital, sin necesidad de acudir a una sucursal bancaria.
            </li>
            <li>
              <b className="font-bold">FLEXIBILIDAD:</b> Personaliza el monto y plazo del préstamo según tus necesidades y posibilidades financieras.
            </li>
            <li>
              <b className="font-bold">ACCESIBILIDAD:</b> Nuestra app está disponible las 24 horas del día, los 7 días de la semana, para que puedas solicitar un préstamo en el momento que más te convenga.
            </li>
            <li>
              <b className="font-bold">CONFIDENCIALIDAD:</b> Respetamos tu privacidad y tratamos tus datos de forma privada. Puedes tener la tranquilidad de que tu información estará protegida.
            </li>
            <li>
              <b className="font-bold">CONVENIENCIA:</b> Accede a tu cuenta y realiza todas tus operaciones desde cualquier lugar y en cualquier momento, a través de tu dispositivo móvil.
            </li>
          </ul>

          <h2 className="text-xl font-bold">¿Cómo usar la aplicación de préstamos de Vivus?</h2>
          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Descarga la app:</b> Dirígete a la tienda de aplicaciones de tu dispositivo móvil y descarga la app de préstamos de Vivus de forma gratuita.
            </li>
            <li>
              <b className="font-bold">Regístrate:</b> Crea una cuenta en la app proporcionando tus datos personales de manera segura.
            </li>
            <li>
              <b className="font-bold">Elige el monto y plazo:</b> Selecciona el monto de préstamo que necesitas y el plazo de pago que mejor se ajuste a tus posibilidades.
            </li>
            <li>
              <b className="font-bold">Completa la solicitud:</b> Rellena el formulario de solicitud con la información requerida, asegurándote de proporcionar datos precisos y veraces.
            </li>
            <li>
              <b className="font-bold">Espera la respuesta:</b> Nuestro sistema evaluará tu solicitud de forma rápida y confiable. Si es aprobada, recibirás una respuesta inmediata.
            </li>
            <li>
              <b className="font-bold">Recibe el dinero:</b> Si tu solicitud es aprobada, nuestro equipo de validación te contactará para corroborar tus datos. Al terminar este proceso, el dinero será depositado directamente en tu cuenta bancaria.
            </li>
            <li>
              <b className="font-bold">Reembolsa el préstamo:</b> Realiza los pagos del monto del préstamo de manera oportuna según los términos y condiciones acordados.
            </li>
          </ol>
          <p>Disfruta de la comodidad y conveniencia de nuestra aplicación de préstamos confiables y obtén el dinero que necesitas de forma rápida y segura.</p>
          <a
            className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl"
            href="https://play.google.com/store/apps/details?id=mx.vivus&utm_source=vivus-website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
          >
            DESCARGAR APP VIVUS
          </a>
        </div>
      </div>
    </Suspense>
  );
};

Page7.propTypes = {};
Page7.defaultValues = {};

export default Page7;
