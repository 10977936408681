import React from "react";
import PropTypes from "prop-types";
import Sld from "react-rangeslider";
// To include the default styles

const Slider = ({ config, min, max, step, value, labelFormatter, update = () => null, validSimulate = () => null }) => {
  return (
    <>
      {config && (
        <div className="w-full relative">
          <Sld
            min={min}
            max={max}
            value={value}
            labels={
              config !== "error"
                ? {
                    [min]: <span className="text-white text-sm font-bold">{labelFormatter.format(min)}</span>,
                    [max]: <span className="text-white text-sm font-bold">{labelFormatter.format(max)}</span>,
                  }
                : { 0: 0, 1: 1 }
            }
            step={step}
            orientation="horizontal"
            tooltip={false}
            onChange={(val) => update(val)}
            onChangeComplete={validSimulate}
          />
        </div>
      )}
    </>
  );
};

export default Slider;
