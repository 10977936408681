import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Page1 = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Préstamos en línea confiables| Vivus Mx</title>
        <meta name="title" content="Préstamos en línea confiables| Vivus Mx" />
        <meta name="description" content="Obtén préstamos en línea confiables de forma rápida y segura. En Vivus, te ofrecemos una solución eficiente a tus necesidades. ¡Solicita AQUÍ tu préstamo!" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1 lg:pb-12">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Préstamos en Línea Confiables - Obtén Dinero Rápido y Seguro</h1>

          <p>
            ¡Bienvenido a Vivus, tu financiera de <b className="font-bold">préstamos en línea confiables</b>! Si estás buscando préstamos en línea rápidos y seguros, estás en el lugar indicado. Nos especializamos en brindarte soluciones financieras de forma ágil y confiable.
          </p>

          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
            SOLICITAR PRÉSTAMO
          </a>

          <h2 className="text-xl font-bold">¿Qué son los préstamos online?</h2>

          <p>
            Los <i className="font-bold">préstamos en línea</i> se han convertido en una alternativa cada vez más popular para obtener dinero rápido y sin complicaciones. Con nuestros créditos en línea, puedes solicitar y recibir el dinero que necesitas de forma ágil y conveniente. Con nosotros se
            terminaron para ti los trámites engorrosos o las largas esperas. Nuestro proceso de solicitud en línea es simple y transparente, lo que significa que puedes completarlo desde la comodidad de tu hogar, en cualquier momento del día. En tu primer préstamo puedes obtener hasta $ 2000, y en
            el segundo puedes solicitar hasta $10000 si lo deseas. Puedes liquidar tu préstamo en el término de hasta 30 días. En Vivus, te brindamos la solución a tus urgencias y a tus sueños pospuestos.
          </p>

          <h2 className="text-xl font-bold">Préstamos en línea rápidos y seguros</h2>

          <p>
            En Vivus, entendemos la importancia de contar con <i className="font-bold">préstamos en línea rápidos y confiables</i> cuando necesitas dinero de forma urgente. Nuestros servicios te brindan la tranquilidad de obtener el financiamiento que requieres justo cuando más lo necesitas. Nuestro
            proceso de solicitud en línea es sencillo y eficiente.
          </p>
          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Sin trámites presenciales</b>: Olvídate de tener que visitar una oficina.{" "}
            </li>
            <li>
              <b className="font-bold">Documentación mínima</b>: Solo necesitarás tu identificación oficial (IFE/INE) y una cuenta bancaria.
            </li>
            <li>
              <b className="font-bold"> Aprobación rápida</b>: Una vez que tu préstamo sea aprobado, nuestro equipo te contactará en breve para recibir tu dinero. Además de ser rápidos, nuestros préstamos son seguros.{" "}
            </li>
          </ul>
          <p>
            En Vivus, nos tomamos muy en serio la protección de tus datos y la fiabilidad de las transacciones. Utilizamos tecnología de encriptación de última generación para garantizar la confidencialidad y protección de tu información personal. Nuestros altos estándares de seguridad y
            transparencia nos han convertido en una opción confiable para miles de clientes. Contamos con la regulación y supervisión DE{" "}
            <a className="text-green-dark underline" href="/informacion-regulatoria">
              CONDUSEF
            </a>
            , lo que brinda tranquilidad y respaldo a nuestros solicitantes.
          </p>

          <a className="bg-green-light hover:bg-green-dark transition-all font-bold text-white py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
            SOLICITAR PRÉSTAMO
          </a>

          <h2 className="text-xl font-bold">¿Cómo solicitar tus créditos en línea sin intereses?</h2>

          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Regístrate en nuestra plataforma</b>: Accede a nuestro sitio web y crea una cuenta.
            </li>
            <li>
              <b className="font-bold">Completa el formulario de solicitud </b>: Proporciona tus datos personales y bancarios. Solo te solicitaremos una foto de tu credencial para votar expedida por el INE para validar tu identidad.
            </li>
            <li>
              <b className="font-bold">Envía tu solicitud</b>: Una vez completado el formulario, envía tu solicitud indicando el monto solicitado y el plazo en que deseas pagar. Luego espera recibir tu aprobación.
            </li>
            <li>
              <b className="font-bold">Recibe tu dinero</b>: Una vez confirmada la operación, recibirás el dinero en tu cuenta bancaria en breve. Por último, antes de la fecha de vencimiento te enviaremos un recordatorio.
            </li>
          </ol>

          <p className="font-bold">¡Aprovecha esta oportunidad y comienza hoy mismo tu solicitud en línea!</p>

          <h2 className="text-xl font-bold">¿Dónde pagar tu préstamo en línea?</h2>

          <p>En Vivus, te brindamos múltiples alternativas para pagar tu préstamo. Puedes realizar tus pagos de forma fácil y segura a través de:</p>
          <ul className="list-disc pl-5">
            <li>Nuestro portal en línea </li>
            <li>Pay Cash </li>
            <li>Oxxo Pay </li>
            <li>Transferencia Bancaria</li>{" "}
          </ul>
          <p>
            Nos adaptamos a tus necesidades para que puedas cumplir con tus compromisos financieros de manera cómoda. Elige la opción que mejor se ajuste a ti y realiza tus pagos sin complicaciones. En Vivus, te facilitamos el proceso para que puedas mantener tu préstamo al día de manera sencilla y
            sin preocupaciones. Ahora ya puedes disfrutar de la flexibilidad que ofrecemos para que puedas pagar tu préstamo en línea de la forma que prefieras.
          </p>

          <h2 className="text-xl font-bold">¿Por qué los préstamos en línea de Vivus son confiables?</h2>

          <ul className="space-y-4">
            <li>
              <b className="font-bold">Tenemos experiencia y respaldo</b>
              <br />
              Somos una empresa de préstamos online con una sólida trayectoria en el mercado financiero.
            </li>
            <li>
              <b className="font-bold">Somos transparentes en nuestras operaciones </b>
              <br />
              En Vivus te ofrecemos información clara y detallada sobre las condiciones de los préstamos. Esto incluye los plazos, tasas de interés y comisiones aplicables. No hay sorpresas ocultas.
            </li>
            <li>
              <b className="font-bold">Solicitar un préstamo en Vivus es rápido y sencillo </b>
              <br />A través de nuestra plataforma en línea, puedes completar el proceso en pocos minutos. No necesitas hacer trámites engorrosos ni presentar documentación complicada.
            </li>
            <li>
              <b className="font-bold">Nos encargamos de la seguridad de tus datos </b>
              <br />
              Valoramos tu privacidad y seguridad. Por ello, utilizamos tecnología avanzada para proteger tus datos personales y financieros.
            </li>
            <li>
              <b className="font-bold">Somos flexibles: </b>
              <br />
              Nos adaptamos a tus necesidades. Ofrecemos opciones de pago flexibles, permitiéndote ajustar las cuotas según tu capacidad financiera. Además, puedes extender los plazos de pago en caso de necesitarlo. Estás a un clic de obtener tu préstamo en línea confiable. ¡Solicítalo AQUÍ!
            </li>
          </ul>
        </div>
      </div>
    </Suspense>
  );
};

Page1.propTypes = {};
Page1.defaultValues = {};

export default Page1;
