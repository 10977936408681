import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { getToken, getCustomerBalance, getFilledContract, getAccountStatement, getAnalytics, getStatus } from "../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import { momentEs } from "../../../services/moment";
import TagManager from "react-gtm-module";
import Pay from "./Pay";
import More from "./More";
import Move from "./Move";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const idProduct = 1;

const Default = ({ history, setBalance, bannerId, flux, setFlux, checkToken, canSoliciteMore }) => {
  const [customerBalance, setCustomerBalance] = useState({
    creditLimit: 0,
    creditLimitUsed: 0,
    liquidateAmount: 0,
  });
  const [contract, setContract] = useState("");
  // const [accStatement, setAccStatement] = useState(null)
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [loadingAcc, setLoadingAcc] = useState(true)
  //const [open, setOpen] = useState(false)
  const [, setVidSrc] = useState("/img/banners/Desktop/Video1.mp4");
  const [, setVidMobSrc] = useState("/img/banners/Mobile/Video1.mp4");
  const [loanNumber, setLoanNumber] = useState(0);
  const { trackPageView } = useMatomo();

  // if (bannerId === null) {
  //     bannerId = 1;
  // } else {
  //     bannerId = parseInt(bannerId)
  // }

  // const handleBannerClick = () => {
  //     switch(bannerId){
  //         case 1:
  //             return history.push('/dashboard/additional-amount')
  //         case 2:
  //             return history.push('/dashboard/additional-amount')
  //         case 5:
  //             return history.push('/dashboard/additional-amount')
  //         default:
  //             break
  //     }
  // }

  const getData = async (user) => {
    setLoading(true);
    // Here goes post to get user debt instead of session storage
    let response = await getToken();
    if (!response) return setServerError(true);
    let {
      data: { token: validToken },
    } = response;
    const data = {
      idProduct,
      idCustomer: user.customerId,
    };

    getStatus(idProduct, user.customerId, false, validToken).then((res) => {
      if (res.status === 200) {
        if (res.data.idStatus === 6) {
          if (res.data.idSubStatus === 15) return history.push("/repeated/application/pre-approved");
        }
        if (res.data.idStatus === 1) {
          if (res.data.idSubStatus === 184) return history.push("/registration");
          if (res.data.idSubStatus === 196) return history.push("/pre-approved");
          if (res.data.idSubStatus === 203) return history.push("/pre-approved");
          if (res.data.idSubStatus === 206) return history.push("/application-review");
          if (res.data.idSubStatus === 217) return history.push("/dashboard/id");
          if (res.data.idSubStatus === 270) return history.push("/loan-review");
          if (res.data.idSubStatus === 271) return history.push("/loan-review");
          if (res.data.idSubStatus === 273) return history.push("/loan-review");

          return history.push("/registration");
        }
        if (res.data.idStatus === 7) {
          setLoading(false);
        }
        if (res.data.idStatus === 8) return history.push("/dashboard");
        if (res.data.idStatus === 4) {
          if (res.data.idSubStatus === 204) return history.push("/rejected");

          return history.push("/denied");
        }
        getCustomerBalance(data, validToken) //
          .then((customerBalance) => {
            setLoanNumber(customerBalance.data.reference);
            if (customerBalance.status === 200) {
              if (customerBalance.data.creditLimitUsed === 0) return history.push("/dashboard");
              setCustomerBalance(customerBalance.data);
              setBalance(customerBalance.data);
              sessionStorage.setItem("balance", JSON.stringify(customerBalance.data));
              trackPageView({
                documentTitle: "Loan Dashboard",
                href: "https://dfi.world",
              });

              return setLoading(false);
            }
            setServerError(true);
            setLoading(false);
          })
          .catch((err) => {
            setServerError(true);
            setLoading(false);
          });

        getFilledContract({ idProduct, idCustomer: user.customerId }, validToken)
          .then((res) => {
            if (res.status === 200) setContract(res.data.document);
          })
          .catch((err) => console.log(err));

        // getAccountStatement(data, validToken)
        // .then(res => {
        //     if(res.data){
        //         setAccStatement(res.data.document)
        //         setLoadingAcc(false)
        //     }
        // })
        // .catch(err =>  console.log(err))

        //TAG MANAGER
        getAnalytics({ idCustomer: user.customerId, idProduct }, validToken).then((analytics) => {
          if (analytics.data) {
            const tagManagerArgs = {
              dataLayer: {
                event: "pageChange",
                page: {
                  url: "/dashboard/payment",
                  referrer: "/login",
                },
                client: {
                  hFN: analytics.data.hFn,
                  hLN: analytics.data.hLN,
                  hTN: analytics.data.hTN,
                  hMA: analytics.data.hMA,
                  dateOfBirth: analytics.data.dateOfBirth,
                  returningClient: analytics.data.returningClient,
                  identifiedBy: analytics.data.identifiedBy,
                  registeredBy: analytics.data.registeredBy,
                },
                loans: {
                  loansCount: analytics.data.loansCount,
                },
                lastest_loan: {
                  status: analytics.data.status,
                  id: analytics.data.id,
                  repaymentDate: analytics.data.repaymentDate,
                },
                application: {
                  id: analytics.data.application.id,
                },
              },
              dataLayerName: "dataLayer",
            };
            TagManager.dataLayer(tagManagerArgs);
          }
        });
        //TAG MANAGER
        return;
      }
      return history.push({
        pathname: "/error",
        state: { endpoint: "getStatus", status: res.status },
      });
    });
  };

  // useEffect(() => {
  //     if(bannerId !== 0){
  //         setVidSrc(process.env.PUBLIC_URL + `/img/banners/Desktop/Video${bannerId}.mp4`)
  //         setVidMobSrc(process.env.PUBLIC_URL + `/img/banners/Mobile/Video${bannerId}.mp4`)
  //     }
  // }, [bannerId])

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (user) {
      if (user.eMail === "demo@demo.com") {
        let dummyData = {
          creditLimit: 5000,
          creditLimitUsed: 3000,
          liquidateAmount: 1500,
          term: 3,
          frequency: 3,
          idFrequency: 3,
          curentInstallment: {
            idDeferral: 1,
            principalBalance: 500,
            interest: 250,
            paymentValue: 560,
            dueDate: new Date(),
          },
          paidAmount: 1000,
          installments: [
            { idDeferral: 1, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 2, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 3, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 4, dueDate: new Date(), paymentValue: 200 },
          ],
          payments: [
            { idDeferral: 1, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 2, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 3, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 4, dueDate: new Date(), paymentValue: 200 },
          ],
        };
        setCustomerBalance(dummyData);
        sessionStorage.setItem("balance", JSON.stringify(dummyData));
        return setBalance(dummyData);
      }
      getData(user);
    }
    console.log("USEEFFECT CALLED ");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="border-2 border-green-dark flex p-8 max-w-4xl items-center flex-col gap-6">
        {
          // loading ||
          !customerBalance?.idCredit ? (
            <BallClipRotate loading className="h-48 w-48 self-center justify-self-center place-self-center" color="#A3CD3A" />
          ) : (
            <>
              {loanNumber !== 0 && (
                <p className="lg:self-start self-center text-center text-xl">
                  <strong>Número de préstamo: </strong>
                  {loanNumber}
                </p>
              )}

              <div className="flex justify-between w-full flex-col lg:flex-row gap-6">
                <div className="flex flex-col gap-2 items-center text-center">
                  <p className="text-xl">Plazo</p>
                  <p className="font-bold text-3xl">{customerBalance.term}</p>
                  <p>{customerBalance.idFrequency === 3 ? "días" : ""}</p>
                </div>
                <div className="flex flex-col gap-2 items-center text-center">
                  <p className="text-xl">Monto total a pagar</p>
                  <p className="font-bold text-3xl">{customerBalance.curentInstallment ? customerBalance.curentInstallment.paymentValue.toLocaleString("en-US", { style: "currency", currency: "USD" }) : 0} MXN</p>
                  <p>IVA incluído</p>
                </div>
                <div className="flex flex-col gap-2 items-center text-center">
                  <p className="text-xl">Fecha de pago</p>
                  <p className="font-bold text-3xl">{customerBalance.curentInstallment ? momentEs(customerBalance.curentInstallment.dueDate).format("D/MMM/Y") : "dd/mm/aaaa"}</p>
                </div>
              </div>
              {contract.length > 0 ? (
                <a href={`data:application/pdf;base64,${contract}`} download="contrato_VIVUS.pdf" className="self-end text-green-dark cursor-pointer underline">
                  Tu contrato
                </a>
              ) : (
                <p className="text-gray-500 self-end">Cargando contrato...</p>
              )}
            </>
          )
        }
      </div>
      <div className="max-w-4xl flex">
        <button
          className={`border-2 border-green-dark flex-1 p-4 uppercase font-bold ${history.location.pathname === "/dashboard/payment" ? "bg-green-dark text-white" : "bg-gray-50 text-green-dark"}`}
          onClick={() => {
            setFlux("pay");
            checkToken();

            history.push("/dashboard/payment");
            setTimeout(() => {
              const e = document.getElementById("payment");
              e.scrollIntoView();
            }, 300);
          }}
        >
          Pagar mi préstamo
        </button>
        <button
          className={`border-2 border-green-dark flex-1 p-4 uppercase font-bold ${history.location.pathname === "/dashboard/extension" ? "bg-green-dark text-white" : "bg-gray-50 text-green-dark"}`}
          onClick={() => {
            history.push("/dashboard/extension#extensions");
            setTimeout(() => {
              const e = document.getElementById("extensions");
              e.scrollIntoView();
            }, 300);
          }}
        >
          Extensión
        </button>
        {canSoliciteMore && (
          <button
            className={`border-2 border-green-dark flex-1 p-4 uppercase font-bold ${history.location.pathname === "/dashboard/additional-amount" ? "bg-green-dark text-white" : "bg-gray-50 text-green-dark"}`}
            onClick={() => {
              history.push("/dashboard/additional-amount");
            }}
          >
            Solicita más dinero
          </button>
        )}
      </div>
      <div className="max-w-4xl">
        <Switch>
          <Route exact path="/dashboard/payment">
            <Pay balance={customerBalance} />
          </Route>

          <Route exact path="/dashboard/extension">
            <Move setFlux={setFlux} loanNumber={loanNumber} />
          </Route>

          <Route exact path="/dashboard/additional-amount">
            <More />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Default);
