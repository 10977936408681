import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Page12 = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>¿Cómo solicitar un préstamo en línea? | Vivus Mx</title>
        <meta name="title" content="¿Cómo solicitar un préstamo en línea? | Vivus Mx" />
        <meta name="description" content="Descubre cómo funciona Vivus y obtén las herramientas que necesitas para saber cómo solicitar un préstamo en línea fácil y rápido." />
        <meta name="keywords" content="solicitar prestamo en linea,solicitar un prestamo en linea" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1 lg:pb-12">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">¿Cómo solicitar un préstamo en línea?</h1>
          <h2 className="text-xl font-bold">¡Tres sencillos pasos para solicitar un préstamo en línea de la manera más conveniente!</h2>

          <ol className="list-decimal pl-5">
            <li>
              Elige el monto y plazo en el simulador, luego presiona “SOLICÍTALO YA”. Al momento de{" "}
              <a className="font-bold text-green-dark" href="https://vivus.com.mx/">
                solicitar un préstamo en línea
              </a>
              , ten en cuenta que puedes solicitar hasta 2.000 MXN en tu primer préstamo y hasta 10.000 MXN en préstamos posteriores.
            </li>
            <li>
              Llena el formulario de registro. ¡No te preocupes! Te vamos a hacer varias preguntas respecto a tu información personal y bancaria. Esta información es sumamente importante para poder brindarte el préstamo de forma rápida y segura. Toda la información es confidencial y sólo será
              utilizada para validar tu préstamo.
            </li>
            <li>
              Luego de brindar toda la información solicitada, espera unos momentos por nuestra confirmación. Si la solicitud es aprobada, serás informado en tu perfil en vivus.com.mx y también mediante un correo electrónico y mensaje de texto. El dinero solicitado será transferido a tu cuenta
              bancaria a la brevedad.
            </li>
          </ol>

          <p>
            Si ya estás registrado previamente en{" "}
            <a href="https://vivus.com.mx/" className="font-bold text-green-dark">
              vivus.com.mx
            </a>
            , ingresa en tu perfil con tu correo electrónico, contraseña y respeta las guías disponibles para solicitar un préstamo en línea por primera vez o para solicitar préstamos subsecuentes. Si ya tienes un préstamo con nosotros, te podemos ofrecer un monto adicional o reprogramar la fecha de
            pago de tu préstamo.
          </p>
        </div>
      </div>
    </Suspense>
  );
};

Page12.propTypes = {};
Page12.defaultValues = {};

export default Page12;
