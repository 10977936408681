import React, { useState, useEffect, lazy, Suspense } from "react";
import "./register.scss";
import { RegisterSteps } from "./RegisterSteps";
import { withRouter } from "react-router-dom";
import { Questionary } from "./steps/Questionary";
import { getStatus, getToken } from "../../services/api";
import cookie from "react-cookies";
import RegisterCalculator from "./RegisterCalculator";

const BasicInfo = lazy(() => import("./steps/BasicInfo"));
const PersonalData = lazy(() => import("./steps/PersonalData"));
const Verification = lazy(() => import("./steps/Verification"));
const Identity = lazy(() => import("./steps/Identity"));
const JobData = lazy(() => import("./steps/JobData"));
const Done = lazy(() => import("./steps/Done"));

let idProduct = 1;

const Register = (props) => {
  const [currentStep, setCurrentStep] = useState(props.url);
  const [proposalChange, setProposalChange] = useState(null);

  const changeProposal = (e) => setProposalChange(e);

  useEffect(() => {
    const scroll = () => window.scrollTo(0, 0);
    return scroll();
  }, [currentStep]);

  useEffect(() => {
    setCurrentStep(props.url);
  }, [props.url]);

  useEffect(() => {
    let checkUser = async () => {
      let validToken = cookie.load("token");
      if (!validToken) {
        let response = await getToken();

        if (!response.data) return;
        if (response.data) validToken = response.data.token;
      }
      let loggedUser = await JSON.parse(sessionStorage.getItem("loggedUser"));
      if (!loggedUser) {
        let emptyCustomer = await JSON.parse(sessionStorage.getItem("empty-customer"));
        if (emptyCustomer) {
          getStatus(idProduct, emptyCustomer.customerId, false, validToken)
            .then((res) => {
              if (sessionStorage.redirectToRegister) {
                return;
              }
              const { data } = res;
              if (res.status === 200) {
                if (data.idStatus === 1) {
                  if (data.idSubStatus === 180) return props.history.push("/registration/personal-details");
                  if (data.idSubStatus === 181) return props.history.push("/registration/employment-details");
                  if (data.idSubStatus === 182) return props.history.push("/registration/nip-bureau");
                  if (data.idSubStatus === 183) return props.history.push("/registration/nip-bureau");
                  if (data.idSubStatus === 184) return props.history.push("/registration/nip-bureau");
                  if (data.idSubStatus === 185) return props.history.push("/registration/nip-bureau");
                  if (data.idSubStatus === 195) return props.history.push("/registration-complete");
                  if (data.idSubStatus === 196) return props.history.push("/pre-approved");
                  if (data.idSubStatus === 203) return props.history.push("/pre-approved");
                  if (data.idSubStatus === 206) return props.history.push("/application-review");
                  if (data.idSubStatus === 217) return props.history.push("/dashboard/id");
                  if (data.idSubStatus === 218) return props.history.push("/application-complete");
                  if (data.idSubStatus === 219) return props.history.push("/application-complete");
                  if (data.idSubStatus === 248) return props.history.push("/application-complete");
                  if (data.idSubStatus === 257) return props.history.push("/identity-validated");
                  if (data.idSubStatus === 270) return props.history.push("/loan-review");
                  if (data.idSubStatus === 271) return props.history.push("/loan-review");
                  if (data.idSubStatus === 273) return props.history.push("/loan-review");
                }
                if (data.idStatus === 4) {
                  return props.history.push("/denied");
                }
                if (data.idStatus === 6) {
                  return props.history.push("/dashboard/general");
                }
                if (data.idStatus === 7) {
                  return props.history.push("/dashboard/general");
                }
                return props.history.push("/dashboard/general");
              }
            })
            .catch((err) => console.log(err));
        }
        return;
      }
      getStatus(idProduct, loggedUser.customerId, false, validToken)
        .then((res) => {
          const { data } = res;

          if (sessionStorage.redirectToRegister) {
            return;
          }
          if (res.status === 200) {
            if (data.idStatus === 1) {
              if (data.idSubStatus === 1) return props.history.push("/registration/");
              if (data.idSubStatus === 180) return props.history.push("/registration/personal-details");
              if (data.idSubStatus === 181) return props.history.push("/registration/employment-details");
              if (data.idSubStatus === 182) return props.history.push("/registration/nip-bureau");
              if (data.idSubStatus === 183) return props.history.push("/registration/nip-bureau");
              if (data.idSubStatus === 184) return props.history.push("/registration/nip-bureau");
              if (data.idSubStatus === 185) return props.history.push("/registration/nip-bureau");
              if (data.idSubStatus === 195) return props.history.push("/registration-complete");
              if (data.idSubStatus === 196) return props.history.push("/pre-approved");
              if (data.idSubStatus === 203) return props.history.push("/pre-approved");
              if (data.idSubStatus === 206) return props.history.push("/application-review");
              if (data.idSubStatus === 217) return props.history.push("/dashboard/id");
              if (data.idSubStatus === 218) return props.history.push("/application-complete");
              if (data.idSubStatus === 219) return props.history.push("/application-complete");
              if (data.idSubStatus === 248) return props.history.push("/application-complete");
              if (data.idSubStatus === 254) return props.history.push("/rejected");
              if (data.idSubStatus === 257) return props.history.push("/identity-validated");
              if (data.idSubStatus === 270) return props.history.push("/loan-review");
              if (data.idSubStatus === 271) return props.history.push("/loan-review");
              if (data.idSubStatus === 273) return props.history.push("/loan-review");
            } else if (data.idStatus === 4) {
              return props.history.push("/denied");
            } else if (data.idStatus === 6) {
              return props.history.push("/dashboard/general");
            } else if (data.idStatus === 7) {
              return props.history.push("/dashboard/general");
            } else {
              return props.history.push("/dashboard/general");
            }
          }
        })
        .catch((err) => console.log(err));
    };
    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white relative w-full flex justify-center flex-1">
      <div className="container flex flex-col gap-4 p-4">
        <div className="w-full overflow-x-auto scroll-smooth">
          <RegisterSteps props={props} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </div>
        <div className="flex flex-1 justify-between flex-col lg:flex-row">
          <div className={currentStep > 2 ? "w-full" : "w-full lg:w-1/2 xl:w-1/3"}>
            <Suspense fallback={<span>Cargando...</span>}>
              {currentStep === 0 ? (
                <BasicInfo props={props.history} currentStep={currentStep} setCurrentStep={setCurrentStep} changeProposal={changeProposal} />
              ) : currentStep === 1 ? (
                <PersonalData props={props} setCurrentStep={setCurrentStep} changeProposal={changeProposal} />
              ) : currentStep === 2 ? (
                <JobData props={props} setCurrentStep={setCurrentStep} changeProposal={changeProposal} />
              ) : currentStep === 3 ? (
                <Verification props={props} setCurrentStep={setCurrentStep} />
              ) : currentStep === 4 ? (
                <Identity props={props} setCurrentStep={setCurrentStep} />
              ) : currentStep === 5 ? (
                <Done props={props} setCurrentStep={setCurrentStep} />
              ) : currentStep === 6 ? (
                <Questionary props={props} setCurrentStep={setCurrentStep} />
              ) : (
                <BasicInfo props={props.history} setCurrentStep={setCurrentStep} />
              )}
            </Suspense>
          </div>
          {currentStep > 2 ? null : (
            <div className="w-full hidden lg:flex lg:w-1/2 xl:w-1/3">
              <RegisterCalculator props={props.history} proposalChange={proposalChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Register);
