import React, { useState, useEffect } from "react";

const DynamicHeadingCover = () => {
  const [headingText, setHeadingText] = useState("Préstamos Personales en línea, fácil aprobación");

  useEffect(() => {
    const updateHeading = () => {
      if (window.innerWidth >= 768) {
        setHeadingText("Préstamos Personales en línea, fácil aprobación");
      } else {
        setHeadingText("Préstamos en línea");
      }
    };

    window.addEventListener("resize", updateHeading);
    updateHeading(); // Initial check

    return () => window.removeEventListener("resize", updateHeading);
  }, []);

  return <h1 className="text-3xl lg:text-4xl xl:text-6xl font-bold text-green-dark lg:mt-0">{headingText}</h1>;
};

export default DynamicHeadingCover;
