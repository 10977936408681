import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { getToken, getCustomerByMail, getStatus, getProposal, getSimulation, getConfiguration, saveProposal, getAnalytics, getCustomerBalance } from "../../services/api";
import { PopInformation } from "../register/PopInformation";
import cookie from "react-cookies";
import { BallClipRotate } from "react-pure-loaders";
import { momentEs } from "../../services/moment";
import TagManager from "react-gtm-module";
import { data } from "autoprefixer";
import LinkButton from "../common/LinkButton";
import Calculator from "../calculator/Calculator";
import Button from "../common/Button";
import { currencyFormatter, decimalFormatter } from "../../utils/format";
import Slider from "../calculator/Slider";
import { Helmet } from "react-helmet";
import moment from "moment";

const idProduct = 1;

export const SecondLoan = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); //CHANGE
  const [customer, setCustomer] = useState(null);
  const [amortizationTable, setAmortizationTable] = useState([]);
  const [registerData, setRegisterData] = useState(null);
  const [monto, setMonto] = useState(1000);
  const [firstPaymentAmount, setFirstPaymentAmount] = useState(0);
  const [plazo, setPlazo] = useState(21);
  const [periodicidad, setPeriodicidad] = useState(3);
  const [cat, setCat] = useState(0);
  const [interesIVA, setInteresIVA] = useState(0);
  const [fecha, setFecha] = useState("dd/mm/aaaa");
  const [commision, setCommision] = useState(0);
  const [serverError, setServerError] = useState(false);
  const [proposalError, setProposalError] = useState(false);
  const [idClient, setIdClient] = useState(0);
  const [loadingAmount, setLoadingAmount] = useState(false);
  const [maxAmount, setMaxAmount] = useState(10000);
  const [showPromo, setShowPromo] = useState(false);

  let today = new Date();

  useEffect(() => {
    const nipEnteredRepeater = sessionStorage.getItem("nip_entered_repeater");
    const askPhoneRepeater = sessionStorage.getItem("ask_phone_repeater");
    if (!askPhoneRepeater && !nipEnteredRepeater) {
      //sessionStorage.setItem("ask_phone_repeater", true);
    }
  }, []);

  const simulate = async (amount, freq, term) => {
    let validToken = await cookie.load("token");
    if (!validToken) {
      let response = await getToken();
      if (!response.data) return props.push("/error");
      if (response.data) validToken = response.data.token;
    }
    getSimulation(idProduct, amount, freq, term, idClient, validToken)
      .then((res) => {
        const { data } = res;
        if (res.status === 200) {
          setCat(data.cat);
          setInteresIVA(data.interest);
          setFirstPaymentAmount(data.firstPaymentAmount);
          setFecha(data.firstDueDate);
          setLoading(false);
          setAmortizationTable(data.amortizationTable);
          setCommision(data.commision);
        }
      })
      .catch((err) => console.log(err));
  };

  const finishProposal = async () => {
    setLoadingAmount(true);
    if (customer.eMail === "demo@demo.com") return props.history.push("/pre-approved");
    let validToken = await cookie.load("token");
    if (!validToken) {
      let response = await getToken();
      if (!response.data) return props.push("/error");
      if (response.data) validToken = response.data.token;
    }
    saveProposal(idProduct, customer.customerId, monto, periodicidad, plazo, validToken).then((res) => {
      setLoadingAmount(false);
      if (res.status === 200) {
        return getStatus(idProduct, customer.customerId, false, validToken).then((res) => {
          if (res.data) {
            if (res.data.idStatus === 1) {
              if (res.data.idSubStatus === 1) {
                sessionStorage.setItem("loadPrefill", true);
                return props.history.push("/registration");
              }
              if (res.data.idSubStatus === 195) {
                sessionStorage.setItem("customer-direct-step", JSON.stringify(5));
                if (props.repeater) return props.history.push("/repeated/application/complete");
                return props.history.push("/registration-complete");
              }
              if (props.repeater) {
                // if (res.data.idSubStatus === 203) return props.history.push("/repeated/credit-check");
                if (res.data.idSubStatus === 206) return props.history.push("/application-review");
                if (res.data.idSubStatus === 219) return props.history.push("/dashboard/id");

                if (res.data.idSubStatus === 257) return props.history.push("/identity-validated");
                // if(res.data.idSubStatus===248) return props.history.push('/application-complete')
              }
              if (res.data.idSubStatus === 196) return props.history.push("/pre-approved");

              if (res.data.idSubStatus === 203) return props.history.push("/pre-approved");

              if (res.data.idSubStatus === 206) return props.history.push("/application-review");
              if (res.data.idSubStatus === 219) return props.history.push("/dashboard/id");
              if (res.data.idSubStatus === 217) return props.history.push("/dashboard/id");

              if (res.data.idSubStatus === 257) return props.history.push("/identity-validated");
              // if(res.data.idSubStatus===248) return props.history.push('/application-complete')
              if (res.data.idSubStatus === 270) return props.history.push("/loan-review");
              if (res.data.idSubStatus === 271) return props.history.push("/loan-review");
              if (res.data.idSubStatus === 273) return props.history.push("/loan-review");
            }
            if (res.data.idStatus === 6) {
              if (props.repeater) return props.history.push("/repeated/application/pre-approved");
              else if (res.data.idSubStatus === 15) return props.history.push("/application-complete");
            }
          }
        });
        // props.history.push('/pre-approved')
      }
      setProposalError(true);
    });
    //TAG MANAGER
    getAnalytics({ idCustomer: customer.customerId, idProduct }, validToken).then((analytics) => {
      if (analytics.data) {
        const tagManagerArgs = {
          dataLayer: {
            event: "pageChange",
            page: {
              url: "/dashboard/payment",
              referrer: sessionStorage.getItem("utm") || "/",
            },
            client: {
              hFN: analytics.data.hFn,
              hLN: analytics.data.hLN,
              hTN: analytics.data.hTN,
              hMA: analytics.data.hMA,
              dateOfBirth: analytics.data.dateOfBirth,
              returningClient: analytics.data.returningClient,
              identifiedBy: analytics.data.identifiedBy,
              registeredBy: analytics.data.registeredBy,
            },
            loans: {
              loansCount: analytics.data.loansCount,
            },
            lastest_loan: {
              status: analytics.data.status,
              id: analytics.data.id,
              repaymentDate: analytics.data.repaymentDate,
            },
            application: {
              id: analytics.data.application.id,
            },
          },
          dataLayerName: "dataLayer",
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    });
    //TAG MANAGER
  };

  const proposal = (customerId, token) => {
    getProposal(idProduct, customerId, token).then((res) => {
      if (res.data) {
        setMonto(res.data.amount);
        setPeriodicidad(res.data.opFrequency);
        setPlazo(res.data.term);
        setMaxAmount(res.data.creditLimit);
        return simulate(res.data.amount, res.data.opFrequency, res.data.term);
      }
      // setMonto(3000)
      // setPeriodicidad(3)
      // setPlazo(4)
      // sessionStorage.setItem('proposal', JSON.stringify({monto: 3000, periodicidad: 1, plazo: 4, idProduct}))
      // simulate(3000, 3, 7)
    });
  };

  const customerBalance = (customerId, token) => {
    getCustomerBalance({ idCustomer: customerId, idProduct }, token).then((res) => {
      console.log(res);
    });
  };

  const updateMonto = (val) => {
    setMonto(val);
  };

  const updatePlazo = (val) => {
    setPlazo(val);
  };

  useEffect(() => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    if (demoUser) return;
    if (!loading) {
      simulate(monto, periodicidad, plazo);
      sessionStorage.setItem("proposal", JSON.stringify({ monto, periodicidad, plazo, idProduct }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monto, periodicidad, plazo]);

  const loadLocalData = async () => {
    let localRegister = await JSON.parse(sessionStorage.getItem("data-step-registration"));
    if (!localRegister) return props.history.push("/login");
    let response = await getToken();
    const validToken = response.data.token;
    getCustomerByMail(idProduct, localRegister.eMail, validToken)
      .then((res) => {
        setIdClient(res.data.customerId);
        setCustomer(res.data);
        proposal(res.data.customerId, validToken);
        customerBalance(res.data.customerId, validToken);
      })
      .catch((err) => console.log(err));
  };

  const getInitConfig = async () => {
    let validToken = await cookie.load("token");
    if (!validToken) {
      let response = await getToken();
      if (!response.data) return props.push("/error");
      if (response.data) validToken = response.data.token;
    }
    const { customerId } = await JSON.parse(sessionStorage.loggedUser);
    getConfiguration(idProduct, validToken, customerId)
      .then((res) => {
        const { data } = res;
        if (res.status === 200) {
          let config = {
            minAmount: data.minAmount,
            maxAmount: data.maxAmount,
            stepAmount: data.stepAmount,
            minTermSem: data.frequencies[0].frequencyTerm.minTerm,
            maxTermSem: data.frequencies[0].frequencyTerm.maxTerm,
          };
          setRegisterData({ sliderConfig: config });
          setMonto(data.defaultAmount);
          setMaxAmount(data.maxAmount);
          setPlazo(data.frequencies[0].frequencyTerm.defaultValue);
          simulate(data.defaultAmount, idProduct, data.frequencies[0].frequencyTerm.defaultValue);
          sessionStorage.setItem(
            "proposal",
            JSON.stringify({
              idProduct,
              monto: data.defaultAmount,
              periodicidad: 3,
              plazo: data.frequencies[0].frequencyTerm.defaultValue,
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const calcConfig = async () => {
    let validToken = await cookie.load("token");
    if (!validToken) {
      let response = await getToken();
      if (!response.data) return props.push("/error");
      if (response.data) validToken = response.data.token;
    }
    getConfiguration(idProduct, validToken)
      .then((res) => {
        const { data } = res;
        if (res.status === 200) {
          let config = {
            minAmount: data.minAmount,
            maxAmount: data.maxAmount,
            stepAmount: data.stepAmount,
            minTermSem: data.frequencies[0].frequencyTerm.minTerm,
            maxTermSem: data.frequencies[0].frequencyTerm.maxTerm,
          };
          setRegisterData({ sliderConfig: config });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const loadConfig = async () => {
      return getInitConfig();
    };
    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialConfig = async () => {
      let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
      let loggedUser = await JSON.parse(sessionStorage.getItem("loggedUser"));
      // if (!loggedUser) {
      //   loggedUser = await JSON.parse(sessionStorage.getItem("empty-customer"));
      // }
      if (demoUser || loggedUser?.eMail === "demo@demo.com") {
        if (loggedUser && loggedUser?.eMail === "demo@demo.com") setCustomer(loggedUser);
        else setCustomer(demoUser);
        setRegisterData({
          sliderConfig: {
            minAmount: 1000,
            maxAmount: 10000,
            stepAmount: 1000,
            minTermSem: 7,
            maxTermSem: 30,
          },
        });
        setMonto(3000);
        setPlazo(17);
        setPeriodicidad(3);
        setCat(321);
        setInteresIVA(500);
        setFirstPaymentAmount(new Date());
        setFecha(new Date());
        setCommision(120);
        setLoading(false);
        return;
      }
      let response = await getToken();
      let validToken = response.data.token;
      const checkUser = async (user) => {
        getStatus(idProduct, user.customerId, false, validToken).then((res) => {
          if (res.status && res.data.idStatus === 1) {
            if (res.data.idSubStatus === 180) return props.history.push("/registration/personal-details");
            if (res.data.idSubStatus === 181) return props.history.push("/registration/employment-details");
            if (res.data.idSubStatus === 182) return props.history.push("/registration/nip-bureau");
            if (res.data.idSubStatus === 183) return props.history.push("/registration/nip-bureau");
            if (res.data.idSubStatus === 184) return props.history.push("/registration/nip-bureau");
            if (res.data.idSubStatus === 185) return props.history.push("/registration/nip-bureau");
            if (res.data.idSubStatus === 195) return props.history.push("/registration-complete");
            // if(res.data.idSubStatus === 196) return props.history.push('/pre-approved')
            // if(res.data.idSubStatus === 203) return props.history.push('/pre-approved')
            if (res.data.idSubStatus === 206) return props.history.push("/application-review");
            if (res.data.idSubStatus === 217) return props.history.push("/application-complete");
            if (res.data.idSubStatus === 218) return props.history.push("/application-complete");
            if (res.data.idSubStatus === 219) return props.history.push("/application-complete");
            if (res.data.idSubStatus === 248) return props.history.push("/application-complete");
          }
          if (res.status && res.data.idStatus === 4) {
            if (res.data.penaltyDays > 0) {
              return props.history.push("/denied");
            }
          }
          if (res.status && res.data.idStatus === 6) {
            return props.history.push("/application-complete");
          }
        });
      };
      //let approved = sessionStorage.getItem('APP')
      //if(approved === 'no') return props.history.push('/denied')
      if (!loggedUser) {
        const emptyCustomer = await JSON.parse(sessionStorage.getItem("empty-customer"));
        if (emptyCustomer) {
          let data = {
            idProduct,
          };
          if (emptyCustomer.idCustomer) {
            data.idCustomer = emptyCustomer.idCustomer;
          } else data.idCustomer = emptyCustomer.customerId;
          setIdClient(data.idCustomer);
          checkUser(emptyCustomer);
          setCustomer({
            customerId: data.idCustomer,
            eMail: emptyCustomer.eMail,
            mobile: emptyCustomer.mobile,
          });
          proposal(data.idCustomer, validToken);
          customerBalance(data.idCustomer, validToken);
        }
        return loadLocalData();
      }
      setCustomer(loggedUser);
      checkUser(loggedUser);
      setIdClient(loggedUser.customerId);
      proposal(loggedUser.customerId, validToken);
      customerBalance(loggedUser.customerId, validToken);
    };
    initialConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let stepWidth = 23;

  useEffect(() => {
    setShowPromo(sessionStorage.returningClient === "RETURNINGSLEEPER");
  }, [sessionStorage.returningClient]);
  return (
    <>
      <Helmet>
        <title>Solicita un nuevo préstamo | Vivus</title>
        <meta name="title" content="Solicita un nuevo préstamo | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-8 px-4 py-12 self-center">
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Solicita un nuevo préstamo</h1>
            <h2 className="text-2xl lg:text-4xl text-green-light">Usa la calculadora para solicitar un nuevo préstamo hasta por $10,000</h2>
          </div>
          <div className="w-full flex justify-center flex-col lg:flex-row">
            {!loading && registerData ? (
              <div className="lg:rounded-xl overflow-hidden bg-white flex flex-col shadow-sm h-auto lg:w-1/2 lg:max-w-2xl text-sm w-full">
                <div className="bg-green-light flex justify-center items-center flex-col p-8 gap-4">
                  <div className="w-full flex flex-col">
                    <div className="flex justify-space w-full justify-between items-center">
                      <p className="text-white font-bold text-lg">Monto total</p>
                      <span className="text-white text-4xl font-bold">${decimalFormatter.format(monto)}</span>
                    </div>
                    <Slider config={registerData.sliderConfig} min={registerData.sliderConfig.minAmount} max={maxAmount} step={registerData.sliderConfig.stepAmount} value={monto} labelFormatter={currencyFormatter} update={updateMonto} onChange={(val) => updateMonto(val)} />

                    {maxAmount < 10000 && (
                      <div className="w-full text-gray-600 mt-6 bg-white p-2 text-xs text-center rounded-xl transition-all font-bold">
                        Recuerda que si pagas tu préstamo a tiempo puedes aumentar tu límite de crédito hasta <span className="text-green-dark">$10,000</span>
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="w-full h-px bg-white mt-10" />
                    <div className="flex justify-space w-full justify-between items-center mt-6">
                      <p className="text-white font-bold text-lg">Plazo</p>
                      <span className="text-white text-4xl font-bold">{plazo} días</span>
                    </div>
                    <Slider
                      config={registerData.sliderConfig}
                      min={periodicidad === 2 ? registerData.sliderConfig.minTermQuin : registerData.sliderConfig.minTermSem}
                      max={periodicidad === 2 ? registerData.sliderConfig.maxTermQuin : registerData.sliderConfig.maxTermSem}
                      step={1}
                      value={plazo}
                      labelFormatter={decimalFormatter}
                      onChange={(val) => updatePlazo(val)}
                      update={updatePlazo}
                    />
                  </div>
                  <div className="py-2 mx-4 mt-4 flex lg:hidden w-full">
                    <Button className="w-full bg-white text-green-dark" onClick={finishProposal}>
                      {loadingAmount ? <BallClipRotate loading color="white" /> : "SOLICÍTALO YA"}
                    </Button>
                  </div>
                </div>
                <div className="px-8 py-4">
                  <div className="flex items-center justify-between py-2">
                    <p className="flex flex-col gap-2">
                      <span>Interés</span>
                    </p>
                    <div>
                      <p className="font-bold">
                        {interesIVA.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                        <small> MXN</small>
                      </p>
                      <p className="text-xs text-right">IVA incluído</p>
                    </div>
                  </div>
                  {showPromo &&
                    // Date is before 1 of november
                    moment.utc().isBefore(moment.utc("2023-11-01")) && (
                      <div className="font-bold text-green-dark p-4 border border-green-dark w-full lg:w-2/3 flex items-center justify-center text-center flex-col ">
                        <span className="text-xl">¡YA TIENES TU DESCUENTO! </span>
                        <b className="">
                          <a href="/perfil" className="text-green-dark underline hover:text-green-light transition-all text-md">
                            IMPORTANTE: Solo haz click este enlace para actualizar tu cuenta bancaria y número de teléfono para activar el descuento.
                          </a>
                        </b>
                        <span className="text-green-dark text-sm">
                          Aplican restricciones, consulta los{" "}
                          <a href="/contenido/terminos-y-condiciones-plazos" className="text-green-dark underline hover:text-green-light transition-all">
                            {" "}
                            términos y condiciones
                          </a>
                          .
                        </span>
                      </div>
                    )}

                  <div className="w-full h-px bg-gray-200" />
                  <div className="flex items-center justify-between py-2">
                    <p className="text-left w-1/2">Comisiones</p>
                    <div>
                      <p className="font-bold text-right">
                        {commision.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                        <small> MXN</small>
                      </p>
                      <p className="text-right text-xs">IVA incluído</p>
                    </div>
                  </div>
                  <div className="w-full h-px bg-gray-200" />
                  <div className="flex items-center justify-between py-2">
                    <p>
                      <strong>Monto a pagar</strong>
                    </p>
                    <div>
                      <p className="text-green-dark">
                        <strong>
                          {firstPaymentAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                          <small> MXN</small>
                        </strong>
                      </p>
                      <p className="text-right text-xs">IVA incluído</p>
                    </div>
                  </div>
                  <div className="w-full h-px bg-gray-200" />
                  <div className="flex items-center justify-between pt-2 pb-4">
                    <p className="text-left">{process.env.REACT_APP_SITE === "mivivus" ? <strong>Primera fecha de pago</strong> : <strong>Fecha de pago</strong>}</p>
                    <p className="text-green-dark">
                      <strong>{momentEs(fecha).format("D/MMM/Y")}</strong>
                    </p>
                  </div>
                  <Button className="w-full hidden lg:flex" onClick={finishProposal}>
                    {loadingAmount ? <BallClipRotate loading color="white" /> : "SOLICÍTALO YA"}
                  </Button>
                  {/* <div className="text-xs text-left py-4">
                  <b>CAT: {cat}%</b> promedio sin IVA para fines informativos y de comparación exclusivamente. <br />
                  Fecha de cálculo: {momentEs(today).format("D/MMM/Y")}
                  <br />
                  <br />
                  Calculadora para fines informativos y de comparación. <br />
                  <br />
                  Nunca solicitamos anticipos, cuotas, pago de pólizas o cualquier otro concepto para otorgarte un préstamo.
                </div> */}
                </div>

                {proposalError ? (
                  <div>
                    <small className="text-red-500">No es posible procesar tu solicitud, por favor intenta más tarde o comunícate con nuestro servicio de atención a clientes.</small>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="rounded-xl mt-24 overflow-hidden bg-white flex flex-col shadow-sm h-auto w-96">
                <div className="bg-green-light flex justify-center items-center flex-col h-16">
                  <BallClipRotate loading color={"white"} />
                </div>
                <div className="p-8">
                  <h2 className="text-center text-xl">Cargando datos...</h2>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
