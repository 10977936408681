import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const Obtain = () => {
  return (
    <div className="flex justify-center items-center flex-1 flex-col ">
      <div className="container px-4 py-12 flex gap-4 flex-col">
        <h1 className="lg:text-4xl text-3xl font-bold">¡Tres sencillos pasos para disponer del dinero en tu cuenta de la manera más conveniente!</h1>
        <ol className="list-decimal p-0 m-0 text-left flex flex-col gap-4">
          <li className="text-lg before:min-w-50px clear-both flex items-center content-center justify-items-center my-4 before:content-['1'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-50px before:mr-4 before:text-2xl before:leading-4 before:text-green-light before:text-center before:border-2 before:border-green-light before:rounded-lg ">
            <p>
              <strong>Elige el monto y plazo en el simulador, luego presiona “SOLICÍTALO YA”</strong>
            </p>
          </li>
          <li className="text-lg before:min-w-50px clear-both flex items-center content-center justify-items-center my-4 before:content-['2'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-50px before:mr-4 before:text-2xl before:leading-4 before:text-green-light before:text-center before:border-2 before:border-green-light before:rounded-lg ">
            <p>
              <strong>Continúa llenando el formulario de registro. </strong>¡No te preocupes! Te vamos a hacer varias preguntas respecto a tu información personal y bancaria. Esta información es sumamente importante para poder brindarte el préstamo de forma rápida y segura. Toda la información es
              confidencial y sólo será utilizada para validar tu préstamo.
            </p>
          </li>
          <li className="text-lg before:min-w-50px clear-both flex items-center content-center justify-items-center my-4 before:content-['3'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-50px before:mr-4 before:text-2xl before:leading-4 before:text-green-light before:text-center before:border-2 before:border-green-light before:rounded-lg ">
            <p>
              Luego de brindar toda la información solicitada, <strong>espera unos momentos por nuestra confirmación.</strong> Si la solicitud es aprobada, serás informado en tu perfil en vivus.com.mx y también mediante un correo electrónico y mensaje de texto. El dinero solicitado será transferido
              a tu cuenta bancaria a la brevedad.
            </p>
          </li>
        </ol>
        <hr className="w-full border border-gray-300 my-4 mx-auto" />
        <div className="flex flex-col lg:flex-row p-4 items-center text-sm">
          <div className="text-green-light text-3xl">
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <div className="p-4 text-left font-light">
            Si ya estás registrado previamente en{" "}
            {process.env.REACT_APP_SITE === "vivus" ? (
              <a href="/login" className="text-green-dark">
                vivus.com.mx
              </a>
            ) : (
              <a href="/login" className="text-green-dark">
                mivivus.com
              </a>
            )}
            ,{" "}
            <Link to="/login" className="text-green-light">
              Ingresar
            </Link>{" "}
            en tu perfil utilizando tu correo electrónico, contraseña y respetando las guías disponibles para solicitar tu préstamo por primera vez o préstamos subsecuentes. <br /> Si ya tienes un préstamo con nosotros, te podemos ofrecer un monto adicional o recorrer la fecha de pago de tu
            préstamo.
          </div>
        </div>
      </div>
    </div>
  );
};
