import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faTimes } from "@fortawesome/free-solid-svg-icons";
import LinkButton from "./LinkButton";
import { Helmet } from "react-helmet";

export const Error = ({ location }) => {
  return (
    <>
      <Helmet>
        <title>Ocurrió un error | Vivus</title>
        <meta name="title" content="Ocurrió un error | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-8 px-4 py-12 self-center">
          <div className="w-full flex flex-col gap-8 text-center">
            <div className="background-error-icon">
              <FontAwesomeIcon icon={faServer} />
              <span style={{ fontSize: "3rem", marginLeft: "1rem" }}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
            <h1 className="text-4xl lg:text-6xl font-bold text-green-dark"> Oops...</h1>
            <h2 className="text-3xl lg:text-4xl text-green-light">Ocurrió un problema en el servidor.</h2>

            <p className="text-2xl">Por favor intenta más tarde</p>
            <p>{location.state ? `${location.state.endpoint} code ${location.state.status}` : null}</p>
            <LinkButton to="/" className="lg:w-96 self-center">
              Regresar a la página principal
            </LinkButton>
          </div>
        </div>
      </div>
    </>
  );
};
