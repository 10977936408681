import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewCard from "./ReviewCard";

const ReviewSlider = ({ reviews = [], title = "¿Qué opinan de nosotros?" }) => {
  let settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    speed: 600,
    slidesToShow: reviews.lengt > 2 ? 3 : reviews.length,
    slidesToScroll: 1,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          swipeToSlide: true,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1248,
        settings: {
          slidesToShow: 2,
          swipeToSlide: true,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: true,
          swipeToSlide: true,
        },
      },
    ],
  };
  return (
    <div className="bg-[#F1F3F8] text-[#65696E] py-16 overflow-x-hidden flex flex-col items-center gap-8">
      <h2 className="md:text-4xl text-3xl font-bold mb-4 text-center">{title}</h2>
      <div className="container max-w-4xl">
        <Slider {...settings} style={{ marginBottom: "40px" }}>
          {reviews.map((review, ix) => (
            <ReviewCard key={ix} review={review} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ReviewSlider;
