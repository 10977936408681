import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CoverSEO from "./shared/CoverSEO";
import ReviewSlider from "./shared/ReviewSlider";
import FAQ from "./shared/FAQ";

const questions = ["¿Qué necesito para solicitar un préstamo en Vivus?", "¿Cuánto tiempo tarda en procesar mi solicitud?", "¿Puedo obtener un préstamo si tengo mal historial crediticio?"];

const answers = ["Solo necesitas una identificación oficial y una cuenta bancaria.", "La evaluación y aprobación se realizan en minutos, y el dinero se deposita en tu cuenta en menos de 24 horas.", "Sí, en Vivus checamos pero no es un factor decisivo. Evaluamos tu capacidad actual de pago."];

const PrestamosTijuana = () => {
  return (
    <Suspense fallback={<span className="min-h-screen flex items-center justify-center text-green-dark font-bold">Cargando...</span>}>
      <Helmet>
        <title>Préstamos en Tijuana - Rápidos y Sin Complicaciones | Vivus Mx</title>
        <meta name="title" content="Préstamos en Tijuana - Rápidos y Sin Complicaciones | Vivus Mx" />
        <meta name="description" content="Obtén préstamos rápidos y sin complicaciones en Tijuana con Vivus. ¡Dinero en minutos!" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="relative w-full flex justify-center flex-1 lg:pb-12 flex-col items-center text-[#65696E]">
        <CoverSEO title="Préstamos en Tijuana: Soluciones Rápidas y Sin Complicaciones" description="Obtén préstamos rápidos y sin complicaciones en Tijuana con Vivus. ¡Dinero en minutos!" />
        <div className="container flex flex-col gap-4 p-4">
          <img src={`/img/seo/seo_tijuana.jpg`} alt="préstamos rápidos tijuana" className="w-full object-cover" />
          <h2 className="text-xl font-bold">Préstamos en Tijuana: Soluciones Rápidas y Sin Complicaciones</h2>
          <p className="font-bold">¿Por Qué Necesitas un Préstamo en Tijuana?</p>
          <p>
            Tijuana es una ciudad dinámica y en constante crecimiento. Las necesidades financieras pueden surgir en cualquier momento, ya sea para una emergencia, una inversión en tu negocio o simplemente para equilibrar tus finanzas personales. En Vivus, estamos aquí para ayudarte con soluciones de
            financiamiento rápidas y seguras.
          </p>
          <h2 className="text-xl font-bold">Ventajas de los Préstamos en Tijuana con Vivus</h2>
          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Rapidos y Eficientes</b>: Obtén tu dinero en minutos sin largos procesos.{" "}
            </li>
            <li>
              <b className="font-bold">Transparencia Total</b>: Conoce todas las condiciones desde el principio, sin sorpresas ocultas, puedes hacerlo en la calculadora de préstamos.
            </li>
            <li>
              <b className="font-bold">Flexibilidad</b>: Ofrecemos opciones de financiamiento que se ajustan a tus necesidades.{" "}
            </li>
            <li>
              <b className="font-bold">Sin Buró de Crédito</b>: No importa tu historial crediticio, evaluamos tu capacidad actual de pago.
            </li>
          </ul>
          <h2 className="text-xl font-bold">Financieras Confiables en Tijuana</h2>
          <p>Vivus se destaca entre las financieras confiables en Tijuana por su compromiso con la transparencia y la seguridad. Con más de 8 años en el mercado, ofrecemos un servicio eficiente y regulado, sin requisitos estrictos de historial crediticio.</p>
          <h2 className="text-xl font-bold">¿Cómo Solicitar un Préstamo en Tijuana?</h2>
          <ol className="list-decimal pl-5">
            <li>
              <b className="font-bold">Solicitud</b>: Crea tu cuenta en{" "}
              <a href="https://www.vivus.com.mx/registration" className="underline text-green-dark">
                VIVUS
              </a>
              .
            </li>
            <li>
              <b className="font-bold">Registro</b>: Ingresa tus datos personales.
            </li>
            <li>
              <b className="font-bold">Evaluación y Aprobación</b>: Tu solicitud será evaluada rápidamente, y en cuestión de minutos, recibirás una respuesta.
            </li>
            <li>
              <b className="font-bold">Depósito del dinero</b>: Si tu solicitud es aprobada, el dinero se deposita directamente en tu cuenta bancaria, listo para ser usado según tus necesidades.
            </li>
          </ol>
          <h2 className="text-xl font-bold">Beneficios de Elegir Vivus para tus Préstamos en Tijuana</h2>
          <ul className="list-disc pl-5">
            <li>
              <b className="font-bold">Sin papeleo</b>: Todo el proceso de tu{" "}
              <a href="https://www.vivus.com.mx/prestamos-personales" className="underline text-green-dark">
                préstamo personal
              </a>{" "}
              es digital, sin necesidad de trámites complicados.
            </li>
            <li>
              <b className="font-bold">Confidencialidad</b>: Tus datos están seguros con nosotros.
            </li>
            <li>
              <b className="font-bold">Flexibilidad de pago</b>: Ofrecemos opciones de pago que se adaptan a tus posibilidades y extensiones en caso de que no puedas cumplir con la fecha límite.
            </li>
          </ul>
        </div>
        <div className="w-screen">
          <ReviewSlider
            title="Reseñas de Clientes en Tijuana"
            reviews={[
              {
                rating: 5,
                text: "Vivus es increíblemente rápido y confiable. Necesitaba un préstamo urgente y en minutos tenía el dinero en mi cuenta.",
                name: "Carlos M.",
              },
              {
                rating: 5,
                text: "La mejor opción en Tijuana para préstamos personales. El proceso fue sencillo y sin complicaciones.",
                name: "María P.",
              },
            ]}
          />
        </div>
        <div className="container flex flex-col gap-4 p-4 mb-8">
          <FAQ questions={questions} answers={answers} title="Preguntas Frecuentes sobre Préstamos en Tijuana" />
          <h2 className="text-xl font-bold">¿Por Qué Alguien en Tijuana Buscaría un Préstamo?</h2>
          <p>
            En Tijuana, las necesidades financieras pueden ser variadas y urgentes. Desde emergencias médicas hasta inversiones en negocios locales, los{" "}
            <a href="https://www.vivus.com.mx" className="underline text-green-dark">
              préstamos en línea{" "}
            </a>{" "}
            y seguros de Vivus ofrecen la solución perfecta para cada situación. No importa cuál sea tu necesidad, estamos aquí para ayudarte.
          </p>
        </div>
      </div>
    </Suspense>
  );
};

PrestamosTijuana.propTypes = {};
PrestamosTijuana.defaultValues = {};

export default PrestamosTijuana;
